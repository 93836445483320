<template>
    <div
      class="modal fade createNewModal"
      id="AddNewCategorieModal"
      tabindex="-1"
      ref="addAddNewCategorieModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">
          </h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
            <h5>Vous voulez ajouter de(s) nouvelle(s) categorie(s) au contrat de: {{ selectedContrat?.marchand?.nom }}</h5>
          <Form ref="categorieForm" @submit="addCategorie" :validation-schema="categorieSchema">
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Catégories <span class="text-danger">*</span>
                    </label>
                    <Field name="categorie" v-slot="{ field }">
                      <Multiselect
                        :options="categoriesOptions"
                        :searchable="true"
                        multiple="true"
                        mode="tags"
                        track-by="label"
                        label="label"
                        v-model = "field.value"
                        v-bind = "field"
                        placeholder="Sélectionner le categorie"
                      />
                    </Field>  
                  </div>
                  <ErrorMessage name="categorie" class="text-danger"/>
                </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Envoyer
              </button>
            </div>
          </Form>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, onMounted, watch} from 'vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as Yup from 'yup';
  import ApiService from '@/services/ApiService';
  import { error, hideModal, success } from '@/utils/utils';
  import { useRouter, useRoute } from 'vue-router';
  import Multiselect from '@vueform/multiselect'
  import { Contrat } from '@/models/Contrat';
import axios from 'axios';
  
  export default {
    name: "AddNewCategorieModal",
    components: {
      Form,
      Field,
      ErrorMessage,
      Multiselect,
    },
    props: {
      selectedContrat: Object as () => Contrat | undefined,
    },
    setup: (props, { emit }) => {
  
      const categorieSchema = Yup.object().shape({
        categorie: Yup.array().required("La categorie est obligatoire"),
      });
  
      const categorieForm =  ref<Contrat | null>(null);
      const addAddNewCategorieModalRef = ref<null | HTMLElement>(null);
      const userForm = ref<Contrat>();
      const route = useRoute();
      const  contrats = ref();
      const categoriesOptions = ref([]);
  
      const fetchCategories = async (contratsId) => {
      try {
        if (selectedContratId.value) {
          const response = await axios.get(`/resteante/contratcategories/${contratsId}`);
          const categoriesData = response.data.data;
          categoriesOptions.value = categoriesData.map((categorie) => ({
            value: categorie.id,
            label: categorie.name,
          }));
        }
      } catch (error) {
        console.error("Une erreur s'est contrate lors de la récupération :", error);
      }
    };
  
    function getContrat(id:number) {
      ApiService.get("/contrats/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            userForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.message);
      });
    }
    
      const selectedContrat = ref<Contrat | undefined>(undefined);
      const selectedContratId = ref<number>();
  
      const addCategorie = async (values, {resetForm}) => {
        const categorieData = {
          contratId: selectedContrat.value?.id,
          categories: values.categorie,
        };
        console.log('rfjfl', categorieData)
      ApiService.post("/contrats/categories/"+categorieData.contratId, categorieData)
        .then(({ data }) => {
          if (data.code == 200) {
          success(data.message);
          resetForm();
          hideModal(addAddNewCategorieModalRef.value);
          emit('close');
          emit('getAllContrat',data.data);
        }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

      watch(() => props.selectedContrat, (newValue) => {
        selectedContrat.value = newValue;
      if(newValue){
        selectedContratId.value = newValue.id;
        fetchCategories(selectedContratId.value);
      }
    });
  
      onMounted(() => {
      if(route.params.id) {
        getContrat(parseInt(route.params.id as string));
      }
    });
  
      return {categorieSchema,
        addCategorie,
         categorieForm,
         addAddNewCategorieModalRef,
          getContrat,
          categoriesOptions,
          selectedContratId
         };
    },
  };
  </script>