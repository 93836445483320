<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
   <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
           <Form ref="categorieForm" @submit="addCategorie" :validation-schema="categorieSchema">
           <div class="row">
             <div class="col-md-4">
               <div class="form-group mb-15 mb-sm-20 mb-md-25">
                 <label class="d-block text-black fw-semibold mb-10">
                   Nom <span class="text-danger">*</span>
                 </label>
                 <Field name="name" type="text" v-model="title"
                   class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                 <ErrorMessage name="name" class="text-danger"/>
               </div>
             </div>
             <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Slug <span class="text-danger">*</span>
                </label>
                <Field name="slug" type="text" readonly="true" v-model="slug"
                  class="form-control shadow-none fs-md-15 text-black" placeholder=""/>
                <ErrorMessage name="slug" class="text-danger"/>
              </div>
            </div>
             <div class="col-md-4">
               <div class="form-group mb-15 mb-sm-20 mb-md-25">
                 <label class="d-block text-black fw-semibold mb-10">
                 Image principale
                 </label>
                 <Field name="urlImage" type="file" 
                       accept="image/jpeg, image/png application/pdf"
                 class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
               <ErrorMessage name="urlImage" class="text-danger"/>
               </div>
             </div>
             <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Ecrire votre message" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie <span class="text-danger">*</span>
                </label>
                <Field  name="categorie"  v-slot="{ field }">
                  <Multiselect
                    :options="categoriesOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner la catégorie"
                  />
                </Field>  
                <ErrorMessage name="categorie" class="text-danger"/>
              </div>
            </div>
             <div class="col-md-12">
                <div class="d-flex align-items-center ">
                  <button class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                    type="submit">
                      Ajouter
                  </button>
                  <router-link to="/liste-categories" 
                      class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                      <span class="position-relative"></span>Annuler</router-link>
                </div>
         </div>
       </div>
     </Form>
   </div>
 </div>
 </template>
 
 <script lang="ts">
 import { defineComponent, onMounted, ref, watch } from "vue";
 import { Form, Field, ErrorMessage } from "vee-validate";
 import { error, success, generateSlug } from "../../../utils/utils";
 import { useRoute, useRouter } from "vue-router";
 import { Categorie } from "../../../models/Categorie";
 import * as Yup from "yup";
 import axios from "axios";
import ApiService from '../../../services/ApiService';
import Multiselect from "@vueform/multiselect/src/Multiselect";
 
 export default defineComponent({
   name: "AddCategorie",
   components: {
     Form,
     Field,
     ErrorMessage,
     Multiselect
   },
   
   setup: () => {
    const categorieSchema = Yup.object().shape({
      name: Yup.string().required("Le nom de la categorie est obligatoire"),
      description: Yup.string().required("La description est obligatoire"),
      // urlImage: Yup.mixed().required("L'image est obligatoire"),
      urlImage: Yup.mixed().notRequired(),
      //link: Yup.string().required("Le lien est obligatoire"),
    categorie: Yup.string().notRequired(),
    });
 
     const categorieForm = ref<Categorie>();
     const router = useRouter();
     const categoriesOptions = ref([]);
     const title = ref();
     const slug = ref();

     onMounted(()=> {
      fetchCatgories()
     })

     watch(title, (newValue) => {
      console.log('title', title.value)
      console.log('generateSlug', slug.value)
      slug.value =  generateSlug(newValue)
     console.log('after', slug.value)
    })
 
     const fetchCatgories = async () => {
      try {
        const response = await ApiService.get('/categories/nopage');
        const categoriesData = response.data.data;
        categoriesOptions.value = categoriesData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };

     const addCategorie = async (values, { resetForm }) => {
      values['slug'] = slug.value;
       console.log("donees envoyees", values);
       axios.post("/categories",values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
         .then(({ data }) => {
           if (data.code == 201) {
             success(data.message);
             //resetForm();
             router.push({ name: "ListeCategoriePage" });
           }
         })
         .catch(({ response }) => {
           error(response.data.message);
         });
     };
 
     return { 
       categorieSchema, 
       addCategorie,
       categorieForm,
       categoriesOptions,
       slug,title,
     };
   },
 });
 </script>
 