<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="faqForm" @submit="addFaq" :validation-schema="faqSchema">
          <div class="row">
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Question <span class="text-danger">*</span>
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  ref="questionEditor"
                  theme="snow"
                  placeholder="Écrire le contenu de votre article"
                  toolbar="full"
                  name="question"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="question" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Réponse <span class="text-danger">*</span>
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  ref="reponseEditor"
                  theme="snow"
                  placeholder="Écrire le contenu de votre article"
                  toolbar="full"
                  name="reponse"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="reponse" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie de faq <span class="text-danger">*</span>
                </label>
                <Field  name="categorieFaq"  v-slot="{ field }">
                  <Multiselect
                    :options="categorieOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner la catégorie"
                  />
                </Field>  
                <ErrorMessage name="categorieFaq" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Tags <span class="text-danger">*</span>
                </label>
                <Field  name="tags"  v-slot="{ field }">
                  <Multiselect
                    :options="tagsOptions"
                    :searchable="true"
                    multiple="true"
                    mode="tags"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le tag"
                  />
                </Field>  
                <ErrorMessage name="tags" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="onSubmit()"
            >
                Ajouter un faq
            </button>
            <router-link to="/liste-faqs" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '../../utils/utils';
import { useRouter } from "vue-router";
import ApiService from '../../services/ApiService';
import { Faq } from '../../models/Faq';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "AddFaq",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const faqSchema = Yup.object().shape({
      //question: Yup.string().required('La question est obligatoire'),
      //reponse: Yup.string().required('La réponse est obligatoire'),
      categorieFaq: Yup.string().required('La catégorie est obligatoire'),
      tags: Yup.array().required('Le tag est obligatoire'),
    });

    const faqForm = ref<Faq | null>(null);
    const router = useRouter();
    const categorieOptions = ref([]);
    const tagsOptions = ref([]);
    const questionEditor = ref();
    const reponseEditor = ref();

    const empty = ref("<p><br></p>")

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(questionEditor.value.getHTML() !== empty.value || reponseEditor.value.getHTML() !== empty.value) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(questionEditor.value.getHTML() === empty.value || reponseEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    const addFaq = async (values, {resetForm}) => {
      values['question'] = questionEditor.value.getHTML();
      values['reponse'] = reponseEditor.value.getHTML();
      console.log('gjggptkm', values)
      if(values['question'] !== empty.value && values['reponse'] !== empty.value) {
        showA.value = false;
      }
      else if(values['question'] === empty.value && values['reponse'] === empty.value) {
        showA.value = true;
      }
      if(showA.value === false) {
      ApiService.post("/faqs",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeFaqPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
      }

    };

    const fetchCategorie = async () => {
      try {
        const response = await ApiService.get('/categorieFaqs');
        const categorieData = response.data.data.data;
        categorieOptions.value = categorieData.map((categorie) => ({
          value: categorie.id,
          label: `${categorie.code} - ${categorie.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchTags = async () => {
      try {
        const response = await ApiService.get('/tags');
        const tagsData = response.data.data.data;
        tagsOptions.value = tagsData.map((tags) => ({
          value: tags.id,
          label: `${tags.nom}`,
        }));
      } catch (error) {
        //
      }
    };


    onMounted(() => {
      fetchCategorie();
      fetchTags();
      console.log(questionEditor.value.getHTML(),'trrrr');
    });

    return { faqSchema, addFaq, 
      faqForm, categorieOptions, tagsOptions,
      questionEditor, reponseEditor,showA, empty,
      onSubmit, show,
    };
  },
});

</script>