<template>
    <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="mediaForm" @submit="addMedia" :validation-schema="mediaSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Titre <span class="text-danger">*</span>
                </label>
                <Field name="titre" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le titre"/>
                <ErrorMessage name="titre" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type Media <span class="text-danger">*</span>
                </label>
                <Field  name="typeMedia"  v-slot="{ field }">
                  <Multiselect
                  v-model="field.value"
                  v-bind="field"
                  placeholder="Rechercher un type de media"
                  :filter-results="false"
                  :min-chars="3"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :options-limit="300"
                  noResultText= "Aucun enregistrement trouvé"
                  noOptionsText="Taper au moins trois caractères"
                  />
                </Field>  
                <ErrorMessage name="typeMedia" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Ecrire votre message" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Article <span class="text-danger">*</span>
                </label>
                <Field  name="article"  v-slot="{ field }">
                  <Multiselect
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Rechercher un article"
                    :filter-results="false"
                    :min-chars="3"
                    :resolve-on-load="false"
                    :delay="0"
                    :searchable="true"
                    :options-limit="300"
                    noResultText= "Aucun enregistrement trouvé"
                    noOptionsText="Tapez au moins trois caractères"
                  />
                </Field>  
                <ErrorMessage name="article" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit"
                >
                    Ajouter un media
                </button>
                <router-link to="/liste-medias" 
                    class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                    <span class="position-relative"></span>Annuler</router-link>
              </div>
            </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '@/utils/utils';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import { Media } from '@/models/Media';
import * as Yup from 'yup';
import axios from 'axios';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "AddMedia",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const mediaSchema = Yup.object().shape({
      titre: Yup.string().required('Le titre est obligatoire'),
      description: Yup.string().notRequired(),
      article: Yup.string().required('L\'article est obligatoire'),
      typeMedia: Yup.string().required('Le type de media est obligatoire'),
    });

    const mediaForm =  ref<Media | null>(null);
    const articles = ref(null);
    const article = ref(0);
    const typeMedia = ref(0);
    const articleOptions = ref([]);
    const router = useRouter();
    const typeMediaOptions = ref([]);

    const addMedia = async (values, {resetForm}) => {
      // ApiService.post("/medias",values)
      //   .then(({ data }) => {
      //     if(data.code == 201) { 
      //       success(data.message);
      //       resetForm();
      //       router.push({ name: "ListeMediasPage" });
      //     }
      //   }).catch(({ response }) => {
      //     error(response.data.message);
      //   });
    };

    // const fetchCasiers = async (key) => {  
    //   try {
    //     const response = await axios.get('/casiers/recherche/'+key);
    //     const casiersData = response.data.data;
    //     casierOptions.value = casiersData.map((casier) => ({
    //       value: casier.id+'-'+casier.code,
    //       label: `${casier.code} - ${casier.etagere.code}`,
    //     }));
    //     return casierOptions.value;
    //   } catch (error) {
    //     //
    //   }
    // }

    // const fetchAbonnements = async (key) => {  
    //   try {
    //     const response = await axios.get('/abonnements/recherche/'+key);
    //     const abonnementsData = response.data.data;
    //     abonnementOptions.value = abonnementsData.map((abonnement) => ({
    //       // value: abonnement.id,
    //       // label: abonnement.abonne.nomComplet+' [ '+abonnement.boite.refBoite+' ]',
    //       value: abonnement.id+'-'+abonnement.loyer,
    //       label: `${abonnement.refAbo} - [${abonnement.abonne.nomComplet}]`,
    //     }));
    //     return abonnementOptions.value;
    //   } catch (error) {
    //     //
    //   }
    // }

    return { mediaSchema,
       addMedia,
        mediaForm,
      };
  },
});
</script>