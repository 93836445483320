<template>
    <BreadCrumb PageTitle="Modifier un apropos" />
    <EditApropos />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import EditApropos from "../../components/Apropos/EditApropos.vue";
  
    export default defineComponent({
      name: "EditAproposPage",
      components: {
        BreadCrumb,
        EditApropos,
      },
    });
  </script>