<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="categorieArticleForm" @submit="addCategorieArticle" :validation-schema="categorieArticleSchema" :init-values="categorieArticleForm">
            <div class="row">
             
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nom <span class="text-danger">*</span>
                  </label>
                  <Field name="nom" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                  <ErrorMessage name="nom" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de catégorie
                </label>
                <Field  name="typeCategorie"  v-slot="{ field }">
                  <Multiselect
                    :options="typeCategorieOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le type de catégorie"
                  />
                </Field>  
                <ErrorMessage name="typeCategorie" class="text-danger"/>
              </div>
            </div>
            <!-- <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Service de la poste
                </label>
                <Field  name="isService"  v-slot="{ field }">
                  <Multiselect
                    :options="serviceOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le service"
                  />
                </Field>  
                <ErrorMessage name="isService" class="text-danger"/>
              </div>
            </div> -->
            <div class="col-md-4" >
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Lien
                  </label>
                  <Field name="lienExterne" type="text" ref="" placeholder=""  class="form-control shadow-none rounded-0 text-black" />
                  <ErrorMessage name="lienExterne" class="text-danger"/>
                </div>
              </div>
             
              <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Sous catégorie 
                </label>
                <Field  name="categorieArticle"  v-slot="{ field }">
                  <Multiselect
                    :options="categorieOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner la catégorie"
                  />
                </Field>  
                <ErrorMessage name="categorieArticle" class="text-danger"/>
              </div>
            </div>
              <div class="col-md-6" >
                <div class="col-md-6 mb-3">
                <!-- <img :src="getUrlApiForFiles(categorie.urlImage)" alt="Image" width="100" height="100" v-if="categorie?.urlImage"> -->
              </div>
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                  Image
                  <a style="float:right;"
                        class="badge badge-outline-danger text-outline-primary"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddImageModal"
                        @click="showImage">
                        Voir l'image
                        <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>
                      </a>
                  </label>
                  <Field name="urlImage" type="file" 
                        accept="image/jpeg, image/png application/pdf"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
                </div>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description<span class="text-danger">*</span>
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Ecrire votre message" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
             <div class="col-md-12">
               <div class="d-flex align-items-center ">
                 <button
                   class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                   type="submit" 
                 >
                     Modifier
                 </button>
                 <router-link to="/liste-categorieArticles" 
                     class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                     <span class="position-relative"></span>Annuler</router-link>
               </div>
         </div>
       </div>
     </Form>
  </div>
</div>

<div
      class="modal fade createNewModal"
      id="AddImageModal"
      tabindex="-1"
      ref="addAddImageModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25 ">
            <h5 class="modal-title fw-bold text-black"> Image actuelle</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
          <img :src="getUrlApiForFiles(categorie?.urlImage)" alt="Image" width="300" height="300" v-if="categorie?.urlImage">
            <h3 v-else>Aucune image</h3>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">

import { defineComponent, ref, watch, onMounted, reactive } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../services/ApiService';
import * as Yup from 'yup';
import { hideModal, getUrlApiForFiles } from '../../utils/utils';
import { CategorieArticle } from '../../models/CategorieArticle';
import { error , success } from '../../utils/utils';
import Multiselect from '@vueform/multiselect'
import axios from 'axios';
import { Categorie } from '@/models/Categorie';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
    name: "EditCategorie",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }) {
    const categorieArticleSchema = Yup.object().shape({
      urlImage: Yup.string().notRequired(),
      nom: Yup.string().required('Le nom est obligatoire'),
      description: Yup.string().required('La description est obligatoire'),
      lienExterne: Yup.string().notRequired(),
      // lienExterne: Yup.string().when([], (value, schema) => {
      //   return estServiceExterne.value
      //     ? schema.required("Le lien externe est obligatoire")
      //     : schema.notRequired()
      // }),
      //isService: Yup.boolean().required('Le service est obligatoire'),
      typeCategorie:Yup.string().required('Le type est obligatoire'),
      categorieArticle: Yup.string().notRequired(),
    });

    const typeCategorieOptions = [
    {
      label: 'Service interne',
      value: '0'
    },
    {
      label: 'Service externe',
      value: '1'
    }
  ];
    const categorieArticleForm = ref<CategorieArticle | null>(null);
    const addCategorieArticleModalRef = ref<null | HTMLElement>(null);
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const title = ref("Ajouter une catégorie d'article");
    const btntext = ref('Ajouter');
    const categorieOptions = ref([]);
    const route = useRoute();
    const router = useRouter();

    watch(() => props.item, (newValue) => {
      getCategorieArticle(newValue);
      getCategorie(newValue.toString());
      isUPDATE.value = true;
    });
    
    const getCategorieArticle = async (id: number) => {
      return ApiService.get("/categorieArticles/"+id)
      .then(({ data }) => {
        const donnees = data.data;
        for (const key in donnees) {
          categorieArticleForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addCategorieArticle = async (values: any) => {
      values = values as CategorieArticle
      if(values['typeCategorie'] == '0'){
        values['isService'] = "Oui";
      }else{
        values['isService'] = "Non";
      }
        console.log('modifier', values)
        axios.put("/categorieArticles/"+values.id,values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              router.push({ name: "ListeCategorieArticlePage" });
              //categorieArticleForm.resetForm();
              //isUPDATE.value=false;
              //btnTitle();
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
    };

    const fetchCategorie = async () => {
      try {
        const response = await ApiService.get('/categorieArticles');
        const categorieData = response.data.data.data;
        categorieOptions.value = categorieData.map((categorie) => ({
          value: categorie.id,
          label: `${categorie.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const showImage = async () => {
      console.log()
    }

    const categorie = ref<Categorie | null>(null);
    function getCategorie(id: string) {
      return ApiService.get("/categorieArticles/"+id.toString())
        .then(({ data }) => {
          categorie.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      fetchCategorie();
      const id = route.params.id as string;
      if(id){
        getCategorie(id);
      }
      if(id){
        getCategorieArticle(parseInt(id));
      }
    });

    return { categorieArticleSchema,
      addCategorieArticleModalRef,
      addCategorieArticle,
      categorieArticleForm,
      title,btntext,resetValue, categorieOptions,
      //state,
      //switchService,
      showImage,getUrlApiForFiles,
      categorie,route,getCategorieArticle,
      getCategorie,typeCategorieOptions,
    };
  },
});
</script>