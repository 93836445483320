<template>
  <div
    class="modal fade createNewModal"
    id="AddStatutCommandeModal"
    tabindex="-1"
    ref="addStatutCommandeModalRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }}</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="statutCommandeForm" @submit="addStatutCommande" :validation-schema="statutCommandeSchema">
            <div class="row">
              <div class="col-md-12" >
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                  Icône
                  </label>
                  <Field name="icon" type="file" 
                        accept="image/jpeg, image/png application/pdf"
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
                </div>
                <ErrorMessage name="icon" class="text-danger"/>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Libellé <span class="text-danger">*</span>
                  </label>
                  <Field name="libelle" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le libellé"/>
                  <ErrorMessage name="libelle" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Ordre <span class="text-danger">*</span>
                  </label>
                  <Field name="ordre" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'ordre"/>
                  <ErrorMessage name="ordre" class="text-danger"/>
                </div>
              </div>
              
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
            {{ btntext }}
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click="resetValue()"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import ApiService from '../../../services/ApiService';
import * as Yup from 'yup';
import { StatutCommande } from '../../../models/StatutCommande';
import { error , success, hideModal } from '../../../utils/utils';
import axios from 'axios';

export default defineComponent({
    name: "AddStatutCommandeModal",
    components: {
    Form,
    Field,
    ErrorMessage
  },
  props: {
    item: {
      type: Number,
      default: 0,
    }
  },
  setup(props, { emit }){
    const statutCommandeSchema = Yup.object().shape({
      icon: Yup.string().notRequired(),
      libelle: Yup.string().required('Le libellé est obligatoire').required("Le libellé est obligatoire"),
      ordre: Yup.number().typeError("Veuillez entrer un nombre").required("L'ordre est obligatoire"),
    });

    const statutCommandeForm = ref<StatutCommande | null>(null);
    const addStatutCommandeModalRef = ref<null | HTMLElement>(null);
    // const item = ref({ ...props.item });
    const localItem = ref(props.item);
    const isUPDATE = ref(false);
    const title = ref("Ajouter un statut de Commande");
    const btntext = ref('Ajouter');

    watch(() => props.item, (newValue) => {
      getStatutCommande(newValue);
      isUPDATE.value = true;
      btnTitle();
    });

    const btnTitle = async () => {
      if (isUPDATE.value) {
         title.value = "Modifier le statut de commande";
         btntext.value = "Modifier";
      }else{
         title.value = "Ajouter un statut de commande";
         btntext.value = "Ajouter";
      }
    }

    const getStatutCommande = async (id: number) => {
      return ApiService.get("/statutCommandes/"+id)
      .then(({ data }) => {
        // map data in form
        const donnees = data.data;
        for (const key in donnees) {
          statutCommandeForm.value?.setFieldValue(key, 
          (typeof donnees[key] === 'object' && donnees[key] !== null)? donnees[key].id :donnees[key]
          );
        }
        emit('openmodal', addStatutCommandeModalRef.value);

      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    }

    const addStatutCommande = async (values: any, statutCommandeForm) => {
      values = values as StatutCommande;
      if(isUPDATE.value){
        // ApiService.put("/statutCommandes/"+values.id,values)
        axios.put("/statutCommandes/"+values.id,values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              statutCommandeForm.resetForm();
              hideModal(addStatutCommandeModalRef.value);
              isUPDATE.value=false;
              btnTitle();
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }else{
        // ApiService.post("/statutCommandes",values)
        axios.post("/statutCommandes",values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              statutCommandeForm.resetForm();
              hideModal(addStatutCommandeModalRef.value);
              emit('close');
            }
        })
        .catch(({ response }) => {
            error(response.data.message);
        });
      }
    };

    const resetValue = () => {
      const formFields = document.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>('input, textarea');
        isUPDATE.value=false;
      formFields.forEach(field => {
        field.value = '';
      });
      btnTitle()
    };

    return { statutCommandeSchema,
      addStatutCommandeModalRef,
      addStatutCommande,
      statutCommandeForm,
      title,btntext,resetValue
    };
  },
});
</script>@/models/StatutCommande