<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="infoFooterForm" @submit="addinfoFooter" :validation-schema="infoFooterSchema">
          <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Politique et conditions d'utilisation générale
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  ref="politiqueConditionEditor"
                  theme="snow"
                  placeholder="Écrire la politique"
                  toolbar="full"
                  name="politiqueCondition"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="politiqueCondition" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Expédition et livraison
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="expeLivraisonEditor"
                  theme="snow"
                  placeholder="Écrire les informations"
                  toolbar="full"
                  name="expeLivraison"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="expeLivraison" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Politique de retour et de remboursement
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="politiqueRREditor"
                  theme="snow"
                  placeholder="Écrire la politique"
                  toolbar="full"
                  name="politiqueRR"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="politiqueRR" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Qui sommes nous ?
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="quiSommeNousEditor"
                  theme="snow"
                  placeholder="Renseigner qui sommes nous ?"
                  toolbar="full"
                  name="quiSommeNous"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="quiSommeNous" class="text-danger"/>
                </div>
              </div>
              
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Contact
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="contactEditor"
                  theme="snow"
                  placeholder="Renseigner le contact"
                  toolbar="full"
                  name="contact"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="contact" class="text-danger"/>
                </div>
              </div>

              <!-- <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Mentions Légales
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="mentionLegaleEditor"
                  theme="snow"
                  placeholder="Écrire les mentions"
                  toolbar="full"
                  name="mentionLegale"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="mentionLegale" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Données personnelles
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="donnePersonnelleEditor"
                  theme="snow"
                  placeholder="Écrire les données"
                  toolbar="full"
                  name="donnePersonnelle"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="donnePersonnelle" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Cookies
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="cookieEditor"
                  theme="snow"
                  placeholder="Écrire les cookies"
                  toolbar="full"
                  name="cookie"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="cookie" class="text-danger"/>
                </div>
              </div> -->

        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="onSubmit()"
            >
                Ajouter à propos
            </button>
            <router-link to="/liste-infoFooter" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '../../utils/utils';
import { QuillEditor } from 'vue-quill-editor';
import { useRouter } from "vue-router";
import ApiService from '../../services/ApiService';
import { InfoFooter } from '../../models/InfoFooter';
import * as Yup from 'yup';
import axios from 'axios';

export default defineComponent({
    name: "AddinfoFooter",
    components: {
    Form,
    // Field,
    QuillEditor,
    ErrorMessage,
  },
  setup: () => {
    const infoFooterSchema = Yup.object().shape({
      // urlImage: Yup.mixed().notRequired(),
      // organigramme: Yup.mixed().notRequired(),
      expeLivraison: Yup.string().notRequired(),//.required('La expeLivraison est obligatoire'),
      politiqueCondition: Yup.string().notRequired(),//.required('La politiqueCondition est obligatoire'),
      politiqueRR: Yup.string().notRequired(),//.required('La politiqueRR est obligatoire'),
      //organigramme: Yup.string().notRequired(),
      contact:Yup.string().notRequired(),//.required(`L'annonce est obligatoire`),
      quiSommeNous: Yup.string().notRequired(),//.required('L\'quiSommeNous est obligatoire'),
    });

    const infoFooterForm = ref<InfoFooter | null>(null); 
    const selectedFile = ref<File | null>();
    const selectedFile2 = ref<File | null>();
    const router = useRouter();
    const contactEditor = ref();
    const expeLivraisonEditor = ref();
    const politiqueConditionEditor = ref();
    const politiqueRREditor = ref();
    const quiSommeNousEditor = ref();
    const donnePersonnelleEditor = ref();
    const mentionLegaleEditor = ref();
    const cookieEditor = ref();
    const empty = ref("<p><br></p>")
    const files = ref(new Array(2).fill([]));

    const onFileChange = (e) => {
      if(e.target.files.length > 0){
        selectedFile.value = e.target.files[0];
        if(e.target.files.length > 1 ){
          selectedFile2.value = e.target.files[1];
        }
      }
      
    };
    

    const handleFileUpload = (index) => (event) => {
      const fileList = event.target.files;
      files.value[index] = Array.from(fileList);
    };

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(contactEditor.value.getHTML() !== empty.value ||
       expeLivraisonEditor.value.getHTML() !== empty.value ||
        quiSommeNousEditor.value.getHTML() !== empty.value ||
         politiqueRREditor.value.getHTML() !== empty.value ||
          politiqueConditionEditor.value.getHTML() !== empty.value
          //  ||
          // cookieEditor.value.getHTML() !== empty.value ||
          // mentionLegaleEditor.value.getHTML() !== empty.value ||
          // donnePersonnelleEditor.value.getHTML() !== empty.value
        ) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(contactEditor.value.getHTML() === empty.value ||
       expeLivraisonEditor.value.getHTML() === empty.value ||
        quiSommeNousEditor.value.getHTML() === empty.value ||
         politiqueRREditor.value.getHTML() === empty.value ||
          politiqueConditionEditor.value.getHTML() === empty.value 
          // ||
          // cookieEditor.value.getHTML() === empty.value ||
          // mentionLegaleEditor.value.getHTML() === empty.value ||
          // donnePersonnelleEditor.value.getHTML() === empty.value
        ) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    const addinfoFooter = async (values, {resetForm}) => {
      values['contact'] = contactEditor.value.getHTML();
      values['expeLivraison'] = expeLivraisonEditor.value.getHTML();
      values['politiqueCondition'] = politiqueConditionEditor.value.getHTML();
      values['politiqueRR'] = politiqueRREditor.value.getHTML();
      values['quiSommeNous'] = quiSommeNousEditor.value.getHTML();
      // values['cookie'] = cookieEditor.value.getHTML();
      // values['mentionLegale'] = mentionLegaleEditor.value.getHTML();
      // values['donnePersonnelle'] = donnePersonnelleEditor.value.getHTML();

      if(values['politiqueRR'] !== empty.value &&
       values['expeLivraison'] !== empty.value &&
        values['politiqueCondition'] !== empty.value &&
         values['quiSommeNous'] !== empty.value &&
          values['contact'] !== empty.value 
          // &&
          //  values['cookie'] !== empty.value &&
          //   values['mentionLegale'] !== empty.value &&
          //    values['donnePersonnelle'] !== empty.value
            ) {
        showA.value = false;
      }else if(values['politiqueRR'] === empty.value &&
       values['expeLivraison'] === empty.value &&
        values['quiSommeNous'] === empty.value &&
         values['politiqueCondition'] === empty.value &&
          values['contact'] === empty.value 
          // &&
          //  values['cookie'] === empty.value &&
          //   values['mentionLegale'] === empty.value &&
          //    values['donnePersonnelle'] === empty.value
            ) 
             {
        showA.value = true;
      }
      if(showA.value === false) {
        axios.post("/infoFooter",values)
          .then(({ data }) => {
            if(data.code == 201) { 
              success(data.message);
              resetForm();
              router.push({ name: "ListeInfoFooterPage" });
            }
          }).catch(({ response }) => {
            error(response.data.message);
          });
      }
    };

    return { 
      infoFooterSchema, 
      addinfoFooter, 
      infoFooterForm, 
      politiqueRREditor, 
      onFileChange, 
      selectedFile, 
      expeLivraisonEditor, 
      quiSommeNousEditor, 
      politiqueConditionEditor,
      cookieEditor,
      mentionLegaleEditor,
      donnePersonnelleEditor,
      handleFileUpload,
      files,
      showA, 
      empty, 
      contactEditor,
      show, onSubmit,

  };
  },
});
</script>