<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="couponForm" @submit="addCoupon" :validation-schema="couponSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text"
                class="form-control shadow-none fs-md-15 input-readonly" placeholder="Entrer le code"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type <span class="text-danger">*</span>
                </label>
                <Field  name="type" v-model="typeC" v-slot="{ field }">
                  <Multiselect
                    :options="typeOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le type"
                  />
                </Field>  
                <ErrorMessage name="type" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Valeur <span class="text-danger">*</span>
                </label>
                <Field name="valeur" type="text" :class="{'cursor-not-allowed': disa}" :disabled="disa"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la valeur"/>
                <ErrorMessage name="valeur" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Montant minimum du panier<span class="text-danger">*</span>
                </label>
                <Field name="montant" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant"/>
                <ErrorMessage name="montant" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Montant maximal du panier
                </label>
                <Field name="montantMax" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant mmaximum"/>
                <ErrorMessage name="montantMax" class="text-danger"/>
              </div>
            </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Date de début <span class="text-danger">*</span>
                  </label>
                  <Field name="dateDebut" type="date" :value="getCurrentDate()"
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="dateDebut" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Date de fin <span class="text-danger">*</span>
                  </label>
                  <Field name="dateFin" type="date" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="dateFin" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10" >
                    Nombre de bénéficiaire <span class="text-danger">*</span>
                  </label>
                  <Field name="useMax" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de bénéficiaire"/>
                  <ErrorMessage name="useMax" class="text-danger"/>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Mettre une description de votre coupon" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Ajouter un coupon
            </button>
            <router-link to="/liste-coupons" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted,watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '../../../utils/utils';
import { useRouter } from "vue-router";
import ApiService from '../../../services/ApiService';
import { Coupon } from '../../../models/Coupon';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "AddCoupon",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const couponSchema = Yup.object().shape({
      code: Yup.string().required('Le code est obligatoire'),
      description: Yup.string().notRequired(),
      montant: Yup.number().typeError('Veuillez entrer des chiffres').required('Le montant est obligatoire'),
      useMax: Yup.number().typeError('Veuillez entrer des chiffres'),
      montantMax: Yup.number().typeError('Veuillez entrer des chiffres'),
      // valeur: Yup.number().typeError('Veuillez entrer des chiffres').required('La valeur est obligatoire'),
      type: Yup.string().required('Le type est obligatoire'),
      dateDebut: Yup.date().typeError("Entrer une date valide").required('La date de début est obligatoire'),
      dateFin: Yup.date().typeError("Entrer une date valide")
        .required('La date de fin est obligatoire')
        .min(Yup.ref('dateDebut'), 'La date de fin ne peut pas être antérieure à la date de début'),
      valeur: Yup.number().typeError('Veuillez entrer des chiffres').when([], (value, schema) => {
      return !valeurM.value
        ? schema.max(100, 'La valeur ne peut pas être supérieure à 100').required("La valeur est obligatoire")
        : schema.notRequired()
      }),
    });

    const couponForm = ref<Coupon | null>(null);
    const router = useRouter();
    const codeCoupon = ref<string>("");
    const typeC = ref(null);
    const disa = ref(true);
    const valeurM = ref(false);
    const typeOptions = 
      [
      {
          value: 1 ,
          label: "%"
      },
      {
          value: 2 ,
          label: "FCFA"
      }
    ];
    watch(typeC, (newVal) => {
      disa.value = newVal !== 1 && newVal !== 2;
      valeurM.value = newVal !== 1;
    });

    // watch(typeC, (newVal) => {
    //   if (newVal === 1 || newVal === 2) {
    //     disable.value = false;
    //     if(newVal === 1){
    //       valeurM.value = false;
    //     }
    //     else {
    //       valeurM.value = true
    //     }
    //   } else {
    //     disable.value = true;
    //   }
    // });

    const addCoupon = async (values, {resetForm}) => {
      console.log('donees envoyees', values)
      ApiService.post("/coupons",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeCouponPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const getCurrentDate = () => {
          const today = new Date();
          const day = String(today.getDate()).padStart(2, '0');
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const year = today.getFullYear();
          return `${year}-${month}-${day}`;
      }

      onMounted(() => {
        codeCoupon.value=genereCode();
        couponForm.value?.setFieldValue("code", "LPB-"+ codeCoupon.value);
    });

      function genereCode():string{
      let charactersArray = 'a-z,A-Z,0-9'.split(',')
      let CharacterSet = '';
      let code = '';
      let size = 6;

      if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
      }

      if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }
      
      if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
      }

      for (let i = 0; i < size; i++) {
        code += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
      }
      return code
    }

    return { couponSchema, addCoupon, couponForm,
      typeOptions,getCurrentDate,codeCoupon, typeC,
      disa,valeurM};
  },
});
</script>

<style >
  .input-readonly {
  background-color: #f0f0f0;
  color: #888888;
  border-color: #cccccc;
}
</style>