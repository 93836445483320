<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="contratForm" @submit="editContrat" :validation-schema="contratSchema">
      <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Marchand <span class="text-danger">*</span>
              </label>
              <Field name="marchand" v-slot="{ field }">
                <Multiselect :options="marchandOptions" :searchable="true" track-by="label" label="label"
                  v-model="field.value" v-bind="field"
                  placeholder="Sélectionner le marchand" />
              </Field>
              <ErrorMessage name="marchand" class="text-danger" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Taux <span class="text-danger">*</span>
              </label>
              <Field name="tauxEconomique" type="text"  class="form-control shadow-none fs-md-15 text-black"
                placeholder="Entrer le taux économique" />
              <ErrorMessage name="tauxEconomique" class="text-danger" />
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Categorie du contrat<span class="text-danger">*</span>
              </label>
              <Field name="categories" v-slot="{ field }">
                <Multiselect :options="categoriesOptions" :searchable="true" track-by="label" multiple="true"
                  mode="tags" label="label" v-bind="field" placeholder="Sélectionner la catégorie" />
              </Field>
              <ErrorMessage name="categories" class="text-danger" />
            </div>
          </div> -->
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Information supplémentaires 
              </label>
              <div class="mb-0">
                <QuillEditor ref="infosSupplementaireEditor" theme="snow" placeholder="Entrer les information supplémentaires" toolbar="full"
                  name="infosSupplementaire" />
              </div>
              <ErrorMessage name="infosSupplementaire" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit">
                Modifier un contrat
              </button>
              <router-link to="/liste-contrats" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
    </Form>
  </div>
</div>

</template>

<script lang="ts">

import { defineComponent, ref, onMounted, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../../services/ApiService';
import { error, success, getUrlApiForProductFiles, generateSlug } from '../../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Contrat } from '../../../models/Contrat';
import Multiselect from '@vueform/multiselect'
import axios from 'axios';

export default defineComponent({
    name: "EditContrat",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const contratSchema = Yup.object().shape({
      tauxEconomique: Yup.string().required('Le taux économique est obligatoire'),
      infosSupplementaire: Yup.string().notRequired(),
      marchand: Yup.string().required("Le marchand est obligatoire"),
      //categories: Yup.string().required('La catégorie est obligatoire'),
    });

    const contratForm = ref<Contrat>();
    const router = useRouter();
    //const categorieOptions= ref([]);
    const categoriesOptions= ref([]);
    const route = useRoute();
    const infosSupplementaireEditor = ref();
    const contrat = ref<Contrat | null>(null);

    const marchandOptions = ref();

  const fetchMarchands = async () => {
      try {
        const response = await ApiService.get('/marchands');
        const marchandsData = response.data.data.data;
        console.log('jefejlj', marchandsData)
        marchandOptions.value = marchandsData.map((marchands) => ({
          value: `${marchands.id}`,
          label: `${marchands.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    function getContrat(id:number) {
      ApiService.get("/contrats/"+id.toString())
        .then(({ data }) => {
          infosSupplementaireEditor.value.setHTML(data.data.infosSupplementaire);
          console.log('descri', data.data)
          const donnees = data.data;
          for (const key in data.data) {
            contratForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
        }
      }).catch(({ response }) => {
        error(response.data.message);
      });
    } 
 
    const editContrat = async (values, {resetForm}) => {
      console.log('ffefe', values)
      values['infosSupplementaire'] = infosSupplementaireEditor.value.getHTML();
      axios.put("/contrats/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeContratPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get('/categories');
        const categoriesData = response.data.data.data;
        categoriesOptions.value = categoriesData.map((categories) => ({
          value: categories.id,
          label: `${categories.name}`,
        }));
      } catch (error) {
        //
      }
    };


    onMounted(() => {
      if(route.params.id) {
        getContrat(parseInt(route.params.id as string));
      }
      fetchCatgories();
      fetchMarchands();
    });

    return {
      contratSchema, 
       editContrat,
       contratForm,
       //categorieOptions,
       categoriesOptions,
       contrat,
       infosSupplementaireEditor,
       marchandOptions,
     };
  },
});
</script>