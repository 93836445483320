<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center" v-if="checkPermission('AddCoupon')">
        <router-link
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-coupon"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter un coupon
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
       <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un coupon"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Code 
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Montant
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Valeur
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Type
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Nbr bénéficiaire
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Montant max
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date début
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date fin
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Statut
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(coupon, index) in coupons" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ coupon.code }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ coupon.montant }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ coupon.valeur }} 
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ coupon.type === "1" ? "Pourcentage (%)" : "FCFA" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ coupon .useMax }} 
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ coupon.montantMax }} 
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(coupon?.dateDebut) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_Date(coupon.dateFin) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <span class="badge badge-outline-danger text-outline-primary">
                  {{ coupon.statut ? "Actif" : "Non Actif" }}  </span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li >
                      <a
                        class="dropdown-item d-flex align-items-center"  v-if="checkPermission('SwitchCoupon')"
                        href="#"
                        @click="switchCoupon(coupon.id)"
                      >
                      <i class="flaticon-flash  lh-1 me-8 position-relative top-1"></i>
                          <span v-if="!coupon.statut"> Activer le coupon</span>
                          <span v-if="coupon.statut"> Désactiver le coupon</span>
                      </a>
                    </li>
                    <!-- <li >
                      <router-link :to="{ name: 'EditCouponPage', params: { id: coupon.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>Modifier</router-link>
                    </li> -->
                    <li v-if="checkPermission('DeleteCoupon')">
                      <a
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" @click="suppression(coupon.id,coupons,'coupons',`le coupon ${coupon.code}`)">
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Supprimer
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2";
import { Coupon } from "../../../models/Coupon";
import ApiService from "../../../services/ApiService";
import { suppression, error,format_Date } from "../../../utils/utils";
import PaginationComponent from '../../../components/Utilities/Pagination.vue';
import JwtService from "../../../services/JwtService";
import axios from "axios";

export default defineComponent({
  name: "ListeCoupon",
  components: {
    PaginationComponent
  },
  setup(){
    
    onMounted(() => {
      getAllCoupons();
    });

    const coupons = ref<Array<Coupon>>([]);   
    const coupon = ref<Coupon>();

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllCoupons(page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllCoupons(page.value, limit.value, searchTerm.value );
    }

   // END PAGINATE

    function getAllCoupons(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/coupons?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          coupons.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }

    // function getAllCoupons() {
    //   return ApiService.get("/coupons")
    //     .then(({ data }) => {
    //       coupons.value = data.data.data
    //       console.log(data.data.data)
    //       return data.data.data;
    //     })
    //     .catch(({ response }) => {
    //       //error(response.data.msg)
    //   });
    // }
    
    function moddifier(EditCoupon:Coupon) {
      coupon.value = EditCoupon;
    }

    const deleteCoupon = (id: number) => {
      ApiService.delete(`/coupons/${id}`)
      .then(({ data }) => {
        Swal.fire({
          text: data.message,
          toast: true,
          icon: 'success',
          title: 'General Title',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          heightAuto: false
        });
      })
      .catch(({ response }) => {
        Swal.fire({
          text: response.data.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Réssayer à nouveau!",
          heightAuto: false,
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      });

      for(let i = 0; i < coupons.value.length; i++) {
        if (coupons.value[i].id === id) {
          coupons.value.splice(i, 1);
        }
      }
    };

    const success = (message) => {
      Swal.fire({
          text: message,
          toast: true,
          icon: 'success',
          title: 'Message',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          heightAuto: false
        });
    };

    const switchCoupon = (id) => {
      axios.put(`/coupon/statut/${id}`)
        .then(({ data }) => {
          if (data.code == 200) {
          success(data.message);
          getAllCoupons();
        }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    }

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }

    return { coupons,
      checkPermission,
     getAllCoupons,
     deleteCoupon,
     moddifier ,
     suppression,
     page, 
    totalPages,
    limit,
    totalElements,
    handlePaginate,
    rechercher,
    searchTerm,
    format_Date,
    switchCoupon
  };
  },
});
</script>