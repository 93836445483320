<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="marchandForm" @submit="addMarchand" :validation-schema="marchandSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type de marchand <span class="text-danger">*</span>
                </label>
                <Field  name="typeMarchand" v-model="typeMarchendId" v-slot="{ field }">
                  <Multiselect
                    :options="typeMarchandOpt"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le type de marchand"
                    @select="handleObjetInput"
                  />
                </Field>  
                <ErrorMessage name="typeMarchand" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6" v-if="showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Nom <span class="text-danger">*</span>
                </label>
                <Field name="nom" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom"/>
                <ErrorMessage name="nom" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6" v-if="showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Prénom <span class="text-danger">*</span>
                </label>
                <Field name="prenom" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le prénom"/>
                <ErrorMessage name="prenom" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6" v-if="!showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Raison sociale <span class="text-danger">*</span>
                </label>
                <Field name="raisonSocial" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la raison social"/>
                <ErrorMessage name="raisonSocial" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Téléphone <span class="text-danger">*</span>
                </label>
                <Field name="telephone" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le telephone"/>
                <ErrorMessage name="telephone" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Email <span class="text-danger">*</span>
                </label>
                <Field name="email" type="email" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le mail"/>
                <ErrorMessage name="email" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6" v-if="!showAdditionalFields">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  RCCM <span class="text-danger">*</span>
                </label>
                <Field name="rccm" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le rccm"/>
                <ErrorMessage name="rccm" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Ifu <span class="text-danger">*</span>
                </label>
                <Field name="ifu" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'ifu"/>
                <ErrorMessage name="ifu" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Adresse de résidence <span class="text-danger">*</span>
                </label>
                <Field name="adresseResidence" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'adresse"/>
                <ErrorMessage name="adresseResidence" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Adresse de boutique <span class="text-danger">*</span>
                </label>
                <Field name="adresseBoutique" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'adresse"/>
                <ErrorMessage name="adresseBoutique" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Secteur d'activité <span class="text-danger">*</span>
                </label>
                <Field  name="secteurActivite"  v-slot="{ field }">
                  <Multiselect
                    :options="secteurActiviteOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le secteur d'activité"
                  />
                </Field>  
                <ErrorMessage name="secteurActivite" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Ajouter un marchand
            </button>
            <router-link to="/liste-marchands" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success } from '../../../utils/utils';
import { useRouter } from "vue-router";
import ApiService from '../../../services/ApiService';
import { Marchand } from '../../../models/Marchand';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "AddMarchand",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const marchandSchema = Yup.object().shape({
      raisonSocial: Yup.string().when([], (value, schema) => {
        return !showAdditionalFields.value
          ? schema.required("La raison social est obligatoire")
          : schema.notRequired()
      }),
      rccm: Yup.string().when([], (value, schema) => {
        return !showAdditionalFields.value
          ? schema.required("Le rccm est obligatoire")
          : schema.notRequired()
      }),
      nom: Yup.string().when([], (value, schema) => {
        return showAdditionalFields.value
          ? schema.required("Le nom est obligatoire")
          : schema.notRequired()
      }),
      prenom: Yup.string().when([], (value, schema) => {
        return showAdditionalFields.value
          ? schema.required("Le prénom est obligatoire")
          : schema.notRequired()
      }),
      // telephone : Yup.number()
      // .typeError('Le téléphone doit être un nombre')
      // .required('Le téléphone est obligatoire')
      // .test(
      //   'is-six-digits',
      //   'Le téléphone doit avoir exactement 8 chiffres',
      //   value => (value ? /^[0-9]{8}$/.test(value.toString()) : true)
      // ),
      telephone : Yup.number().typeError('Veuillez entrer des chiffres').required('Le téléphone est obligatoire'),
      email: Yup.string().required('L\'email est obligatoire').email("Entrer une adresse email valide"),
      adresseResidence: Yup.string().required('L\'adresse de residence est obligatoire'),
      adresseBoutique: Yup.string().required('L\'adresse boutique est obligatoire'),
      typeMarchand: Yup.string().required('Le type de marchand est obligatoire'),
      // ifu : Yup.number()
      // .typeError('Le téléphone doit être un nombre')
      // .required('L\'ifu est obligatoire')
      // .test(
      //   'is-six-digits',
      //   'L\'ifu doit avoir 12 chiffres',
      //   value => (value ? /^[0-9]{12}$/.test(value.toString()) : true)
      // ),
      //type: Yup.string().required('Le type est obligatoire'),
      ifu: Yup.string()
      .length(13, 'La saisie doit avoir exactement 13 caractères')
      .matches(/^\d{13}$/, 'La saisie doit être composée uniquement de chiffres')
      .transform((value) => value.slice(0, 13))
      .required('L\'ifu est obligatoire'),
      // ifu: Yup.number().typeError('Veuillez entrer des chiffres').required('L\'ifu est obligatoire'),
      secteurActivite: Yup.string().required('Le secteur d\'activité est obligatoire')

    });

    const marchandForm = ref<Marchand | null>(null);
    const router = useRouter();
    const marchandsOptions = ref([]);
    const secteurActiviteOptions= ref([]);

    const typeMarchandOpt = 
      [
      {
          value: "1" ,
          label: "Personne physique"
      },
      {
          value: "2" ,
          label: "Personne morale"
      }
    ];

    const typeMarchendId = ref("1");
    const showAdditionalFields = ref(true);

    const handleObjetInput = (selectedValue) => {
      showAdditionalFields.value = selectedValue === "1";
    }

    // const typeOpt = 
    //   [
    //   {
    //       value: "Poste" ,
    //       label: "Poste"
    //   },
    //   {
    //       value: "Externe" ,
    //       label: "Externe"
    //   }
    // ];

    const addMarchand = async (values, {resetForm}) => {
      console.log('donees envoyees', values)
      ApiService.post("/marchands",values)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeMarchandPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const fetchSecteursActivites = async () => {
      try {
        const response = await ApiService.get('/secteuractivites');
        const secteurActivitesData = response.data.data.data;
        secteurActiviteOptions.value = secteurActivitesData.map((secteurActivite) => ({
          value: secteurActivite.id,
          label: `${secteurActivite.libelle}`,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchSecteursActivites();
    });

    return { marchandSchema, addMarchand, marchandForm,
      marchandsOptions, typeMarchandOpt, secteurActiviteOptions,
      typeMarchendId,handleObjetInput,showAdditionalFields,
    };
  },
});
</script>