<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="couponForm" @submit="editCoupon" :validation-schema="couponSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Code <span class="text-danger">*</span>
                </label>
                <Field name="code" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le code"/>
                <ErrorMessage name="code" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Valeur <span class="text-danger">*</span>
                </label>
                <Field name="valeur" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la valeur"/>
                <ErrorMessage name="valeur" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Montant <span class="text-danger">*</span>
                </label>
                <Field name="montant" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant"/>
                <ErrorMessage name="montant" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Type <span class="text-danger">*</span>
                </label>
                <Field  name="type"  v-slot="{ field }">
                  <Multiselect
                    :options="typeOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le type"
                  />
                </Field>  
                <ErrorMessage name="type" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier un coupon
            </button>
            <router-link to="/liste-coupons" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../../services/ApiService';
import { error, success } from '../../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Coupon } from '../../../models/Coupon';
import Multiselect from '@vueform/multiselect'

export default defineComponent({
    name: "EditCoupon",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const couponSchema = Yup.object().shape({
      code: Yup.string().required('Le nom est obligatoire'),
      montant: Yup.number().typeError('Veuillez entrer des chiffres').required('Le montant est obligatoire'),
      valeur: Yup.number().typeError('Veuillez entrer des chiffres').required('La valeur est obligatoire'),
      type: Yup.string().required('Le type est obligatoire'),
    });

    const couponForm = ref<Coupon>();
    const router = useRouter();
    const route = useRoute();
    const typeOptions = 
      [
      {
          value: "Type 1" ,
          label: "Type 1"
      },
      {
          value: "Type 2" ,
          label: "Type 2"
      }
    ];

    function getCoupon(id:number) {
      ApiService.get("/coupons/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            couponForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editCoupon = async (values, {resetForm}) => {
      console.log('ffefe', values)
      ApiService.put("/coupons/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListeCouponPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };


    onMounted(() => {
      if(route.params.id) {
        getCoupon(parseInt(route.params.id as string));
      }
    });

    return { couponSchema, editCoupon, couponForm,
      typeOptions, };
  },
});
</script>