<template>
    <BreadCrumb PageTitle="Ajouter une catégorie" />
    <AddCategorie />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../../components/Common/BreadCrumb.vue";
    import AddCategorie from "../../../components/Ecommerce/Categorie/AddCategorie.vue";
  
    export default defineComponent({
      name: "AddCategoriePage",
      components: {
        BreadCrumb,
        AddCategorie,
      },
    });
  </script>