<template>
  <div
    class="modal fade createNewModal"
    id="ZoneLivreurModal"
    tabindex="-1"
    ref="addZoneLivreurModalRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">
        </h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <h5>Vous voulez complèter des zones de couverture à: {{ selectedLivreur?.nomComplet }} </h5>
        <Form ref="livreurForm" @submit="addLivreur" :validation-schema="livreurSchema">
          <div class="row">
            <div class="col-md-12" >
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Zone d'intervention <span class="text-danger">*</span>
                </label>
                <Field  name="communes"  v-slot="{ field }">
                  <Multiselect
                    :options="communesOptions"
                    :searchable="true"
                    track-by="label"
                    multiple="true"
                    mode="tags"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner la zone"
                  />
                </Field>
                <ErrorMessage name="communes" class="text-danger"/>
              </div>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              Envoyer
            </button>
          </div>
        </Form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch} from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error, hideModal, success } from '@/utils/utils';
import { useRouter, useRoute } from 'vue-router';
import Multiselect from '@vueform/multiselect'
import { Commande } from '@/models/Commande';
import axios from 'axios';
import JwtService from '@/services/JwtService';
import { User } from '@/models/users';

export default {
  name: "ZoneLivreurModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    selectedLivreur: Object as () => Commande | undefined,
  },
  setup: (props, { emit }) => {

    const livreurSchema = Yup.object().shape({
      communes: Yup.array().required("La commune est obligatoire"),
    });

    const livreurForm =  ref<Commande | null>(null);
    const addZoneLivreurModalRef = ref<null | HTMLElement>(null);
    const userId = JwtService.getUser()!;
    const estLivreur = 1;
    const user = ref<User>();
    const communesOptions = ref([]);
  
    const selectedLivreur = ref<Commande | undefined>(undefined);
    const selectedLivreurId = ref<number>();

    const addLivreur = async (values, {resetForm}) => {
    ApiService.post(`/zone/livreur/${selectedLivreurId.value}`, values)
      .then(({ data }) => {
        if (data.code == 200) {
        success(data.message);
        resetForm();
        hideModal(addZoneLivreurModalRef.value);
        emit('close');
        emit('getAllCommandes',data.data);
      }
      }).catch(({ response }) => {
        error(response.data.message);
      });
  };

    watch(() => props.selectedLivreur, (newValue) => {
      selectedLivreur.value = newValue;
    if(newValue){
      selectedLivreurId.value = newValue.id;
      console.log(newValue.id)
      fetchCommune(selectedLivreurId.value);
    }
  });

  const fetchCommune = async (a:any) => {
        ApiService.get(`/zonerestant/livreurs/${a}`)
          .then(({ data }) => {
              const donneee = data.data;
              console.log('données', donneee)
              communesOptions.value = donneee.map((commune: any) => {
                return {
                  label: commune.libelle,
                  value: commune.id,
                };
              });
          })
          .catch(({ response }) => {
          //error(response.data.message);
        });
      }

    onMounted(() => {
      // fetchCommune()
  });

    return {livreurSchema,
      addLivreur,
       livreurForm,
       addZoneLivreurModalRef,
        selectedLivreurId,
        userId,
        user,communesOptions,
        // selectedLivreur,
       };
  },
};
</script>