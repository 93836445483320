<template>
    <BreadCrumb PageTitle="Détails d'un apropos" />
    <ViewApropos/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ViewApropos from "../../components/Apropos/ViewApropos.vue";

    export default defineComponent({
      name: "ViewAproposPage",
      components: {
        BreadCrumb,
        ViewApropos,
      },
    });
  </script>