<template>
    <div
      class="modal fade createNewModal"
      id="AddNewTagModal"
      tabindex="-1"
      ref="addAddNewTagModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
            <h5 class="modal-title fw-bold text-black">
          </h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
            <h5>Vous voulez ajouter de(s) nouveau(x) tag(s) à ce faq: {{ selectedFaq?.id }}</h5>
          <Form ref="tagForm" @submit="addTag" :validation-schema="tagSchema">
            <div class="row">
                <div class="col-md-12">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Tags <span class="text-danger">*</span>
                    </label>
                    <Field name="tag" v-slot="{ field }">
                      <Multiselect
                        :options="tagsOptions"
                        :searchable="true"
                        multiple="true"
                        mode="tags"
                        track-by="label"
                        label="label"
                        v-model = "field.value"
                        v-bind = "field"
                        placeholder="Sélectionner le tag"
                      />
                    </Field>  
                  </div>
                  <ErrorMessage name="tag" class="text-danger"/>
                </div>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
              >
                Envoyer
              </button>
            </div>
          </Form>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, onMounted, watch} from 'vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import * as Yup from 'yup';
  import ApiService from '@/services/ApiService';
  import { error, hideModal, success } from '@/utils/utils';
  import { useRouter, useRoute } from 'vue-router';
  import Multiselect from '@vueform/multiselect'
  import { Faq } from '@/models/Faq';
import axios from 'axios';
  
  export default {
    name: "AddNewTagModal",
    components: {
      Form,
      Field,
      ErrorMessage,
      Multiselect,
    },
    props: {
      selectedFaq: Object as () => Faq | undefined,
    },
    setup: (props, { emit }) => {
  
      const tagSchema = Yup.object().shape({
        tag: Yup.array().required("Le tag est obligatoire"),
      });
  
      const tagForm =  ref<Faq | null>(null);
      const addAddNewTagModalRef = ref<null | HTMLElement>(null);
      //const router = useRouter();
      const userForm = ref<Faq>();
      const route = useRoute();
      const  faq = ref();
      const tagsOptions = ref([]);
  
      const fetchTags = async (faqId) => {
      try {
        if (selectedFaqId.value) {
          const response = await axios.get(`/resteante/faqtags/${faqId}`);
          const tagsData = response.data.data;
          tagsOptions.value = tagsData.map((tag) => ({
            value: tag.id,
            label: tag.nom,
          }));
        }
      } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des rôles :", error);
      }
    };
  
    function getFaq(id:number) {
      ApiService.get("/faqs/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            userForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.message);
      });
    }
    
      const selectedFaq = ref<Faq | undefined>(undefined);
      const selectedFaqId = ref<number>();
  
      const addTag = async (values, {resetForm}) => {
        const tagData = {
          faqId: selectedFaq.value?.id,
          tags: values.tag,
        };
        console.log('rfjfl', tagData)
      ApiService.post("/faqs/tags/"+tagData.faqId, tagData)
        .then(({ data }) => {
          if (data.code == 200) {
          success(data.message);
          resetForm();
          hideModal(addAddNewTagModalRef.value);
          emit('close');
          emit('getAllFaq',data.data);
        }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

      watch(() => props.selectedFaq, (newValue) => {
        selectedFaq.value = newValue;
      if(newValue){
        selectedFaqId.value = newValue.id;
        fetchTags(selectedFaqId.value);
      }
    });
  
      onMounted(() => {
      if(route.params.id) {
        getFaq(parseInt(route.params.id as string));
      }
    });
  
      return {tagSchema,
        addTag,
         tagForm,
         addAddNewTagModalRef,
          getFaq,
          tagsOptions,
          selectedFaqId
         };
    },
  };
  </script>