<template>
 <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="alaUneForm" @submit="addAlaUne" :validation-schema="alaUneSchema">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Image principale<span class="text-danger">*</span>
                </label>
                <Field name="urlImage" type="file" 
                      accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
              <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Sous titre <span class="text-danger">*</span>
                </label>
                <Field name="subtitle" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le sous titre"/>
                <ErrorMessage name="subtitle" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Titre <span class="text-danger">*</span>
                </label>
                <Field name="titre" type="text" 
                  class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le titre"/>
                <ErrorMessage name="titre" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Lien <span class="text-danger">*</span>
                </label>
                <Field name="link" type="text" 
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le lien"/>
              <ErrorMessage name="link" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Description <span class="text-danger">*</span>
              </label>
              <div class="mb-0">
                <QuillEditor
                  ref="descriptionEditor"
                  theme="snow"
                  placeholder="Écrire le contenu"
                  toolbar="full"
                  name="description"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
            </div>
          </div>
            
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit" @click="onSubmit()"
                >
                    Ajouter
                </button>
                <router-link to="/liste-alaUnes" 
                    class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                    <span class="position-relative"></span>Annuler</router-link>
              </div>
        </div>
      </div>
    </Form>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success } from "../../utils/utils";
import { useRoute, useRouter } from "vue-router";
import ApiService from "../../services/ApiService";
import { AlaUne } from "../../models/AlaUne";
import * as Yup from "yup";
import axios from "axios";

export default defineComponent({
  name: "AddAlaUne",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  
  setup: () => {
    const alaUneSchema = Yup.object().shape({
      titre: Yup.string().required("Le titre est obligatoire"),
      subtitle: Yup.string().required("Le sous titre est obligatoire"),
      description: Yup.string().notRequired(),
      urlImage: Yup.mixed().required("L'image est obligatoire"),
      link: Yup.string().required("Le lien est obligatoire"),
    });

    const alaUneForm = ref<AlaUne | null>(null);
    const router = useRouter();

    const descriptionEditor = ref();
    const empty = ref("<p><br></p>")

    onMounted(()=> {
      //
    })

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(descriptionEditor.value.getHTML() !== empty.value) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(descriptionEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    const addAlaUne = async (values, { resetForm }) => {
      console.log("donees envoyees", values);
      values['description'] = descriptionEditor.value.getHTML();
      if(values['description'] !== empty.value) {
        showA.value = false;
      }
      else if(values['description'] === empty.value) {
        showA.value = true;
      }
      if(showA.value === false) {
      axios.post("/alaUnes",values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            resetForm();
            router.push({ name: "ListeAlaUnePage" });
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
      }
    };

    return { 
      alaUneSchema, 
      addAlaUne,
      alaUneForm,
      descriptionEditor,
       showA, empty ,
       onSubmit,show
    };
  },
});
</script>
