<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center" v-if="checkPermission('AddCategorieArticle')">
        <router-link
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-categorieArticle"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter une catégorie d'article
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une catégorie d'article"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Nom
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Description
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Type de service
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Service
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Lien 
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Sous catégorie
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(categorie, index) in categorieArticles" :key="index">
              <!-- <td class="shadow-none lh-1 fw-medium text-primary">
                <img :src="getUrlApiForFiles(categorie.urlImage)" alt="Image" width="100" height="100">
              </td> -->
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ categorie.nom }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ categorie.description.slice(0, 20) + '...' }}
              </td>
              <!-- <td
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
              <span v-if="categorie.typeCategorie == '0'" >Service Interne</span>
              <span v-if="categorie.typeCategorie == '1'" >Service Externe</span>
              </td> -->
              <td
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
              <span class="badge text-outline-info">{{categorie.isService}}</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ categorie.lienExterne }}
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ categorie.categorieArticle?.nom }}
              </td> -->
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
              <div class="dropdown">
                  <span class="badge text-white bg-primary fs-15 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                      <i class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('EditCategorieArticle')">
                      <router-link :to="{ name: 'EditCategorieArticlePage', params: { id: categorie.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>Modifier</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'ViewCategorieArticlePage', params: { id: categorie.id } }" class="dropdown-item d-flex align-items-center">
                            <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>Détails
                        </router-link>
                    </li>
                    <li v-if="checkPermission('DeleteCategorieArticle')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);" @click="suppression(categorie.id,categorieArticles,'categorieArticles',`la catégorie ${categorie.nom}`)"
                      >
                        <i
                          class="flaticon-delete lh-1 me-8 position-relative top-1" 
                        ></i>
                        Supprimer
                      </a>
                    </li>
                  </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
      
      <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
        
      </div>
    </div>
  </div>
  <!-- <AddCategorieArticleModal :item="selectedItem"   @close="recharger" :selectedId="selectedId"/> -->
</template>
<script lang="ts">

import { defineComponent, onMounted, ref, watch, computed } from "vue";
// import AddCategorieArticleModal from "./AddCategorieArticleModal.vue";
import ApiService from "../../services/ApiService";
import { format_date, suppression, error, getUrlApiForFiles } from "../../utils/utils";
import {CategorieArticle} from '../../models/CategorieArticle';
import axios from 'axios';
import  Swal  from "sweetalert2";
import Paginate from 'vuejs-paginate'
import PaginationComponent from '../../components/Utilities/Pagination.vue';
import JwtService from "../../services/JwtService";

export default defineComponent({
    name: "ListeCategorieArticle",
    components: {
      PaginationComponent
  },
  setup: () => {
    const modules = {
  
    };
    
    const categorieArticles = ref<Array<CategorieArticle>>([]);
    const categorieArticle = ref<CategorieArticle>();
    const addCategorieArticleModalRef = ref<null | HTMLElement>(null);
    const selectedItem = ref(0);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0); 

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllCategorieArticles(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher(){
      getAllCategorieArticles(page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    function getAllCategorieArticles(page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`categorieArticles?page=${page}&limit=${limi}&mot=${searchTerm}&`)
      .then(({ data }) => {
        categorieArticles.value = data.data.data;
        totalPages.value = data.data.totalPages;
        limit.value = data.data.limit;
        totalElements.value = data.data.totalElements;
      })
      .catch(({ response }) => {
        error(response.data.message)
      });
    } 

    onMounted(() => {
      getAllCategorieArticles();
    });

    const selectedId = ref<number>();

    function moddifier(EditCategorie:CategorieArticle) {
      categorieArticle.value = EditCategorie;
      selectedItem.value = EditCategorie.id;
      selectedId.value = EditCategorie.id;
    }
    const recharger = () => {
      getAllCategorieArticles();
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    }


    return { modules,
      checkPermission,
            selectedItem,
            categorieArticles,
            recharger,
            suppression, 
            moddifier, 
            page, 
            totalPages,
            limit,
            totalElements,
            getAllCategorieArticles,
            handlePaginate,
            searchTerm,
            rechercher,
            addCategorieArticleModalRef,
            getUrlApiForFiles,
            selectedId,
    };
  },
});
</script>
@/models/CategorieInfo