<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
    <Form ref="categorieForm" @submit="editCategorie" :validation-schema="categorieSchema">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10" >
                  Nom <span class="text-danger">*</span>
                </label>
                <Field name="name" type="text" v-model="title"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nom de la catégorie"/>
                <ErrorMessage name="name" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Slug <span class="text-danger">*</span>
                </label>
                <Field name="slug" type="text" readonly="true" v-model="slug"
                  class="form-control shadow-none fs-md-15 text-black" placeholder=""/>
                <ErrorMessage name="slug" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                 Image principale<span class="text-danger">*</span>
                 <a style="float:right;"
                        class="badge badge-outline-danger text-outline-primary"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddImageModal"
                        @click="showImage">
                        Voir l'image
                        <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>
                      </a>
                </label>
                <Field name="urlImage" type="file" 
                      accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Description 
                  </label>
                  <Field name="description" as="textarea"  cols="30"
                    rows="12" placeholder="Entrer la description" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                  <textarea
                  class="form-control shadow-none rounded-0 text-black"
                    v-model="field.value"
                  ></textarea>
                </Field>
                  <ErrorMessage name="description" class="text-danger"/>
                </div>
              </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Catégorie <span class="text-danger">*</span>
                </label>
                <Field  name="categorie"  v-slot="{ field }">
                  <Multiselect
                    :options="categoriesOptions"
                    :searchable="true"
                    track-by="label"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner la catégorie"
                  />
                </Field>  
                <ErrorMessage name="categorie" class="text-danger"/>
              </div>
            </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Modifier une categorie
            </button>
            <router-link to="/liste-categories" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
  </div>
</div>

<div
      class="modal fade createNewModal"
      id="AddImageModal"
      tabindex="-1"
      ref="addAddImageModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25 ">
            <h5 class="modal-title fw-bold text-black"> Image actuelle</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
          <img :src="getUrlApiForFiles(categorie?.urlImage)" alt="Image" width="300" height="300" v-if="categorie?.urlImage">
            <h3 v-else>Aucune image</h3>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">

import { defineComponent, ref, onMounted,watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '../../../services/ApiService';
import { error, success, getUrlApiForFiles, generateSlug } from '../../../utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { Categorie } from '../../../models/Categorie';
import Multiselect from '@vueform/multiselect'
import axios from 'axios';

export default defineComponent({
    name: "EditCategorie",
    components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  setup: () => {
    const categorieSchema = Yup.object().shape({
      urlImage: Yup.string().notRequired(),
      //urlImage: Yup.string().required("L'image est obligatoire"),
      name: Yup.string().required('Le nom est obligatoire'),
      categorie: Yup.string().notRequired(),
      description: Yup.string().notRequired(),
    });

    const categorieForm = ref<Categorie>();
    const router = useRouter();
    const route = useRoute();
    const categoriesOptions = ref([]);

    const categorie = ref<Categorie | null>(null);

    const title = ref();
     const slug = ref();

    watch(title, (newValue) => {
      console.log('title', title.value)
      console.log('generateSlug', slug.value)
      slug.value =  generateSlug(newValue)
     console.log('after', slug.value)
    })


    function getCategorieView(id: string) {
      return ApiService.get("/categories/"+id)
        .then(({ data }) => {
          categorie.value = data.data; 
        })
        .catch(({ response }) => {
         // error(response.data.message);
        });
    } 

    function getCategorie(id:number) {
      ApiService.get("/categories/"+id.toString())
        .then(({ data }) => {
          for (const key in data.data) {
            categorieForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editCategorie = async (values, {resetForm}) => {
      console.log('ffefe', values)
      axios.put("/categories/"+values.id,values,{ headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            // resetForm();
            router.push({ name: "ListeCategoriePage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    const fetchCatgories = async () => {
      try {
        const response = await ApiService.get('/categories/nopage');
        const categoriesData = response.data.data;
        categoriesOptions.value = categoriesData.map((categorie) => ({
          value: categorie.id,
          label: `${categorie.name}`,
        }));
      } catch (error) {
        //
      }
    };

    const showImage = async () => {
      console.log()
    }

    onMounted(() => {
      if(route.params.id) {
        getCategorie(parseInt(route.params.id as string));
      }
      if(route.params.id) {
        getCategorieView(route.params.id as string);
      }
      fetchCatgories();
    });

    return { categorieSchema, editCategorie, categorieForm,
      categoriesOptions,categorie, getUrlApiForFiles,showImage,
      slug,title,
    };
  },
});
</script>