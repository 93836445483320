<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="articleForm" @submit="editArticle" :validation-schema="articleSchema" :initial-values="articleForm">

      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">  
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" 
          data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Information générale</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Méta data</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent ">
        <div class="tab-pane fade show active p-10" id="home-tab-pane" role="tabpanel" tabindex="0">  
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Titre <span class="text-danger">*</span>
                    </label>
                    <Field name="titre" type="text" v-model="title"
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le titre"/>
                    <ErrorMessage name="titre" class="text-danger"/>
                  </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10" >
                        Alias <span class="text-danger">*</span>
                      </label>
                      <Field name="alias" type="text" readonly="true" v-model="alias"
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'alias"/>
                    <ErrorMessage name="alias" class="text-danger"/>
                    </div>
                  </div>
                <div class="col-md-4">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Sous titre <span class="text-danger">*</span>
                    </label>
                    <Field name="sousTitre" type="text" 
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le sous titre"/>
                    <ErrorMessage name="sousTitre" class="text-danger"/>
                  </div>
                </div>
                <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Contenu de l'article <span class="text-danger">*</span>
                  </label>
                  <div class="mb-0">
                    <QuillEditor
                      ref="contenuEditor"
                      theme="snow"
                      placeholder="Écrivez le contenu"
                      toolbar="full"
                      name="contenu"
                    />
                  </div>
                  <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>

                  <!-- <p class="text-danger" v-if="!showA.value">Ce champ est obligatoire</p> -->
                </div>
              </div>
              <div class="product-upload">
                    <p>Autres fichiers</p>
                    <div class="dz-message needsclick">
                      <DropZone 
                        name="fichiers[]"
                        :maxFiles="Number(10000000000)"
                        :uploadOnDrop="true"
                        :multipleUpload="true"
                        :parallelUpload="3"
                        @addedFile="onFileAdd"
                      />
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Image principale<span class="text-danger">*</span>
                      <a style="float:right;"
                        class="badge badge-outline-danger text-outline-primary"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddImageModal"
                        @click="showImage">
                        Voir l'image
                        <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>
                      </a>
                    </label>
                    <Field name="urlImage" type="file" v-model="urlImage"
                          accept="image/jpeg, image/png, application/pdf"
                          class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
                    <ErrorMessage name="urlImage" class="text-danger"/>
                  </div>
                </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                      Icône de l'article<span class="text-danger">*</span>
                      <a
                      style="float:right;"
                        class="badge badge-outline-danger text-outline-primary"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddIconeModal"
                        @click="showIcone()"
                      >
                      Voir l'icône 
                      <i class="flaticon-eye lh-1 me-8 position-relative top-1"></i>

                      </a>
                      </label>
                      <Field name="icone" type="file" v-model="icon"
                            accept="image/jpeg, image/png application/pdf"
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une icône"/>
                    <ErrorMessage name="icone" class="text-danger"/>
                    </div>
                  </div>
                <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10">
                          Catégorie <span class="text-danger">*</span>
                        </label>
                        <Field name="categorieArticle" v-slot="{ field }">
                          <Multiselect
                            :options="categorieArticlesOptions"
                            :searchable="true"
                            track-by="label"
                            label="label"
                            v-model = "field.value"
                            v-bind = "field"
                            placeholder="Sélectionner la catégorie"
                          />
                        </Field>  
                      </div>
                      <ErrorMessage name="categorieArticle" class="text-danger"/>
                    </div>

                    <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Publier l'article ? <span class="text-danger">*</span>
                    </label>
                    <Field  name="estPublie"  v-slot="{ field }">
                      <Multiselect
                        :options="publi"
                        :searchable="true"
                        track-by="label"
                        label="label"
                        v-model = "field.value"
                        v-bind = "field"
                        placeholder="Sélectionner l'option"
                      />
                    </Field>  
                  <ErrorMessage name="estPublie" class="text-danger"/>
                  </div>
                </div>
                <!-- <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Tags <span class="text-danger">*</span>
                    </label>
                    <Field name="tags" v-slot="{ field }" v-model="defaultSelectedTags">
                      <Multiselect
                        :options="tagsOptions"
                        :searchable="true"
                        multiple="true"
                        mode="tags"
                        track-by="label"
                        label="label"
                        v-bind = "field"
                        placeholder="Sélectionner le tag"
                      />
                    </Field>  
                  </div>
                  <ErrorMessage name="tags" class="text-danger"/>
                </div> -->
                  <div class="col-md-6">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Date de publication
                      </label>
                      <Field name="datePublication" type="date" 
                      class="form-control shadow-none fs-md-15 text-black"/>
                    </div>
                  <ErrorMessage name="datePublication" class="text-danger"/>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Source de l'article
                      </label>
                      <Field name="source" type="text" 
                      class="form-control shadow-none fs-md-15 text-black"/>
                    </div>
                  <ErrorMessage name="source" class="text-danger"/>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Niveau
                      </label>
                      <Field name="numero" type="text" 
                      class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="numero" class="text-danger"/>
                    </div>
                  </div>
                </div>
              </div>  
                <div class="tab-pane fade p-10" id="profile-tab-pane" role="tabpanel" tabindex="0">
                <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10" >
                          Meta title 
                        </label>
                        <Field name="metaTitle" type="text" 
                        class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le meta title"/>
                      </div>
                      <ErrorMessage name="metaTitle" class="text-danger"/>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <label class="d-block text-black fw-semibold mb-10" >
                          Meta Keyword
                        </label>
                        <Field name="metaKeyword" type="text" 
                        class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le meta keyword"/>
                      </div>
                      <ErrorMessage name="metaKeyword" class="text-danger"/>
                    </div>
                    <div class="col-md-12">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Meta description 
                      </label>
                      <div class="mb-0">
                        <QuillEditor
                          ref="metaDescriptionEditor"
                          theme="snow"
                          placeholder="Ecrire la meta description"
                          toolbar="full"
                          name="metaDescription"
                        />
                      </div>
                      <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                      <!-- <p class="text-danger" v-if="!showA.value">Ce champ est obligatoire</p> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex align-items-center ">
                    <button
                      class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                      type="submit"
                    >
                        Modifier un article
                    </button>
                    <router-link to="/liste-articles" 
                        class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                        <span class="position-relative"></span>Annuler</router-link>
                  </div>
                </div>
            </div>
          </div>
    </Form>
    </div>
  </div>

  <div
      class="modal fade createNewModal"
      id="AddImageModal"
      tabindex="-1"
      ref="addAddImageModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25 ">
            <h5 class="modal-title fw-bold text-black"> Image actuelle</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
          <img :src="getUrlApiForFiles(article?.urlImage)" alt="Image" width="300" height="300" v-if="article?.urlImage">
            <h3 v-else>Aucune image</h3>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>

    <div
      class="modal fade createNewModal"
      id="AddIconeModal"
      tabindex="-1"
      ref="addAddIconeModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25 ">
            <h5 class="modal-title fw-bold text-black"> Icone actuelle</h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
          <img :src="getUrlApiForFiles(article?.icone)" alt="Image" width="300" height="300" v-if="article?.icone">
            <h3 v-else>Aucune icone</h3>
          </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>

    <div class="modal fade createNewModal"
     id="PreviewOrActiveArticleModal"
     tabindex="-1"
     ref="addPreviewOrActiveArticleModalRef"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md"> 
    <div class="modal-content p-15 p-md-40">
      <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
        <h5 class="modal-title text-black">
          L'article: {{ createdArticle?.titre }} a bien été modifié
        </h5>
      </div>
      <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex align-items-center justify-content-center mb-3">
              <button class="default-btn me-3 transition border-0 fw-medium text-white pt-2 pb-2 ps-3 pe-3 rounded-1 fs-md-15 fs-lg-16 bg-success"
                      @click="previewArticle(createdArticleId, createdArticleIdd)">
                Prévisualiser l'article
              </button>
              <button @click="switchArticle(createdArticleId, createdArticleIdd)" 
                      class="default-btn me-3 transition border-0 fw-medium text-white pt-2 pb-2 ps-3 pe-3 rounded-1 fs-md-15 fs-lg-16 bg-danger">
                      Activer l'article
              </button>
            </div>
          </div>
        </div>
      </div>
      <button type="button"
              class="btn-close shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close">
      </button>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success,getUrlApiForFiles, showModal, hideModal, generateSlug  } from "../../utils/utils";
import { useRoute, useRouter } from "vue-router";
import ApiService from "../../services/ApiService";
import { Article } from "../../models/Article";
import * as Yup from "yup";
import Multiselect from '@vueform/multiselect'
import DropZone from "dropzone-vue";
import axios from "axios";

export default defineComponent({
  name: "EditArticle",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    DropZone,

  },
  setup: (props, { emit }) => {
    const articleSchema = Yup.object().shape({
      titre: Yup.string().required("Le titre est obligatoire"),
      sousTitre: Yup.string().required("Le sous titre est obligatoire"),
      //contenu: Yup.string().notRequired(),
      alias: Yup.string().required("L'alias est obligatoire"),
      metaTitle: Yup.string().notRequired(),
      metaKeyword: Yup.string().notRequired(),
      //metaDescription: Yup.string().notRequired(),
      urlImage: Yup.string().notRequired(),
      icone: Yup.string().notRequired(),
      estPublie: Yup.boolean().required("L'option est obligatoire"),
      //tags: Yup.array().required("Les tags est obligatoire"),
      datePublication: Yup.date().notRequired(),
      source: Yup.string().notRequired(),
      numero: Yup.number().typeError('Veuillez entrer des chiffres').notRequired(),
      categorieArticle:Yup.number().typeError('Veuillez entrer des chiffres').notRequired()
    });

    const articleForm = ref<Article | null>(null);
    const router = useRouter();
    const route = useRoute();
    const article = ref<Article | null>(null);

    const contenuEditor = ref();
    const metaDescriptionEditor = ref();
    const empty = ref("<p><br></p>")
    const tagsOptions = ref([]);
    const categorieArticlesOptions = ref([]);
    const toutFichier = ref<Array<File>>([]);
    const createdArticle = ref();
    const createdArticleId = ref();
    const createdArticleIdd = ref();
    const addPreviewOrActiveArticleModalRef = ref<null | HTMLElement>(null);

      const publi =ref( 
    [
      {
          value: true ,
          label: "Publié"
      },
      {
          value: false ,
          label: "Dépublié"
      }
    ]);
    const defaultSelectedTags = ref();
    const urlImage =ref("");
    const imageName =ref("");
    const icon =ref("");

    const title= ref('');
    const alias= ref('');

    watch(title, (newValue) => {
      console.log('title', title.value)
      console.log('generateSlug', alias.value)
      alias.value = generateSlug(newValue)
      console.log('after', alias.value)
    })

    function getArticleView(id: string) {
      return ApiService.get("/articles/"+id)
        .then(({ data }) => {
          article.value = data.data; 
          console.log('Data-data', data.data)
        })
        .catch(({ response }) => {
         // error(response.data.message);
        });
    } 

    function getArticle(id:number) {
      ApiService.get("/articles/"+id.toString())
        .then(({ data }) => {
          contenuEditor.value.setHTML(data.data.contenu);
          metaDescriptionEditor.value.setHTML(data.data.metaDescription);
          const donnees = data.data;
          urlImage.value= donnees.urlImage
          imageName.value= donnees.urlImage
          icon.value= donnees.icone
          //icon.value= data.data.icone
          console.log('Data image', donnees.urlImage);
          console.log('Data icon', donnees.icone);
        const articletags = donnees.articletags;
        let ids: number[] = [];
        for (const articletag of articletags) {
          ids.push(articletag.id); 
        }
        articletags.value= ids;
        defaultSelectedTags.value = articletags.value;
          for (const key in data.data) {
            articleForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const onFileAdd = (file:any) => {
      console.log(file);
      console.log(file["file"]);
      toutFichier.value.push(file["file"]);
    };

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(contenuEditor.value.getHTML() !== empty.value) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(contenuEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    const editArticle = async (values, {resetForm}) => {
      if(toutFichier.value.length > 0){
        values['autreFichier[]'] = [];
        values['autreFichier[]'] =  toutFichier.value.map((fichier) => (fichier));
      }
      values['contenu'] = contenuEditor.value.getHTML();
      values['metaDescription'] = metaDescriptionEditor.value.getHTML();
      if(values['contenu'] !== empty.value) {
        showA.value = false;
      }
      else if(values['contenu'] === empty.value) {
        showA.value = true;
      }
      values['estPublie'] = JSON.parse(values['estPublie']);
      console.log('Données',values)
      if(showA.value === false) {
      axios.put("/articles/"+values.id,values,{ headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            // resetForm();
            const modalEl = document.getElementById("PreviewOrActiveArticleModal");
              if (modalEl) {
                showModal(modalEl);
              }
              createdArticle.value = values;
              createdArticleId.value = data.data.alias
              createdArticleIdd.value = data.data.id
              console.log('Article', createdArticle.value)
              console.log('createdArticleId', createdArticleId.value)
              hideModal(addPreviewOrActiveArticleModalRef.value);
              router.push({ name: "ListeArticlePage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    }
    };

    const previewArticle = (alias: string, createdArticleIdd: number) => {
      const url = `http://localhost:8081/#/preview-article/${alias}`;
      // window.location.href = url;
      window.open(url, '_blank');
    };
    
    const switchArticle = (id: number, createdArticleIdd: number) => {
      console.log()
      axios.put(`/article/publie/${createdArticleIdd}`)
        .then(({ data }) => {
          if (data.code == 200) {
            success(data.message);
            router.push({ name: "ListeArticlePage" });
            hideModal(addPreviewOrActiveArticleModalRef.value);
            emit('close');
            emit('getAllArticle',data.data);
            router.push({ name: "ListeArticlePage" });

          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };
//     const editArticle = async (values, { resetForm }) => {
//     let formData = new FormData();

//       // Ajoutez les fichiers, s'il y en a
//       if (toutFichier.value.length > 0) {
//       toutFichier.value.forEach((fichier) => {
//       formData.append('autreFichier[]', fichier);
//       });
//       }

//       // Ajoutez le contenu et la metaDescription
//       formData.append('contenu', contenuEditor.value.getHTML());
//       formData.append('metaDescription', metaDescriptionEditor.value.getHTML());
//       // formData.append('urlImage', metaDescriptionEditor.value.getHTML());
//       // formData.append('icone', metaDescriptionEditor.value.getHTML());

//       // Vérifiez si le contenu ou la metaDescription est vide
//       showA.value = values['contenu'] === empty.value || values['metaDescription'] === empty.value;

//       // Si tout est rempli, envoyez la requête
//       if (!showA.value) {
//       try {
//       const response = await ApiService.put(`/articles/{values.id}`,formData,{ headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } } );

//       if (response.data.code == 200) {
//       success(response.data.message);
//       resetForm();
//       router.push({ name: "ListeArticlePage" });
//       }
//     } catch (errorResponse) {
//     //error(errorResponse.response.data.message);
//     }
//     }
// };


    // const fetchTags = async () => {
    //   try {
    //     const response = await ApiService.get('/tags');
    //     const tagsData = response.data.data.data;
    //     tagsOptions.value = tagsData.map((tags) => ({
    //       value: tags.id,
    //       label: `${tags.nom}`,
    //     }));
    //   } catch (error) {
    //     //
    //   }
    // };

    const showImage = async () => {
      console.log()
    }

    const showIcone = async () => {
      console.log()
    }


    const fetchCategorieArticles = async () => {
      try {
        const response = await ApiService.get('/categorieArticles?limit=0');
        const categorieArticlesData = response.data.data.data;
        categorieArticlesOptions.value = categorieArticlesData.map((categorieArticle) => ({
          value: categorieArticle.id,
          label: `${categorieArticle.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      if(route.params.id) {
        getArticle(parseInt(route.params.id as string));
      }
      //fetchTags()
      fetchCategorieArticles();
      const id = route.params.id as string;
      getArticleView(id);
    });

    return { articleSchema, articleForm, editArticle, tagsOptions,
    contenuEditor, metaDescriptionEditor,publi, showA, empty, defaultSelectedTags,
    categorieArticlesOptions,show, onSubmit, 
    onFileAdd,showImage,urlImage,imageName,icon,getUrlApiForFiles,article,
    showIcone,previewArticle,
    createdArticle,
      switchArticle,
      createdArticleId,
      addPreviewOrActiveArticleModalRef,
      alias,title,createdArticleIdd,
  };
  },
});
</script>

<style scoped>
  @import 'dropzone-vue/dist/dropzone-vue.common.css';
</style>