<template>
    <BreadCrumb PageTitle=" la Une" />
    <AddAlaUne />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddAlaUne from "../../components/AlaUne/AddAlaUne.vue";
  
    export default defineComponent({
      name: "AddAlaUnePage",
      components: {
        BreadCrumb,
        AddAlaUne,
      },
    });
  </script>