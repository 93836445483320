<template>
 <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
  <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <Form ref="articleForm" @submit="addArticle" :validation-schema="articleSchema">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">  
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" 
                data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Information générale</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Méta data</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent ">
              <div class="tab-pane fade show active p-10" id="home-tab-pane" role="tabpanel" tabindex="0">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Titre <span class="text-danger">*</span>
                      </label>
                      <Field name="titre" type="text" v-model="title"
                        class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le titre"/>
                      <ErrorMessage name="titre" class="text-danger"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10" >
                        Alias <span class="text-danger">*</span>
                      </label>
                      <Field name="alias" type="text" readonly="true" v-model="alias"
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'alias"/>
                    <ErrorMessage name="alias" class="text-danger"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Sous titre <span class="text-danger">*</span>
                      </label>
                      <Field name="sousTitre" type="text" 
                        class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le sous titre"/>
                      <ErrorMessage name="sousTitre" class="text-danger"/>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Contenu de l'article <span class="text-danger">*</span>
                      </label>
                      <div class="mb-0">
                        <QuillEditor
                          ref="contenuEditor"
                          theme="snow"
                          placeholder="Écrivez le contenu"
                          toolbar="full"
                          name="contenu"
                        />
                      </div>
                      <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                    </div>
                  </div>
                  <div class="product-upload">
                      <p>Autres fichiers</p>
                      <div class="dz-message needsclick">
                        <DropZone 
                          name="fichiers[]"
                          :maxFilesize="5000"
                          :maxFiles="Number(10000000000)"
                          :uploadOnDrop="true"
                          :multipleUpload="true"
                          :parallelUpload="3"
                          :filesizeBase="5000"
                          @addedFile="onFileAdd"
                        />
                      </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                      Image principale
                      </label>
                      <Field name="urlImage" type="file" 
                            accept="image/jpeg, image/png application/pdf"
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
                    <ErrorMessage name="urlImage" class="text-danger"/>

                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                      Icône de l'article
                      </label>
                      <Field name="icone" type="file" 
                            accept="image/jpeg, image/png application/pdf"
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une icône"/>
                    <ErrorMessage name="icone" class="text-danger"/>

                    </div>
                  </div>

                  <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Catégorie <span class="text-danger">*</span>
                    </label>
                    <Field name="categorieArticle" v-slot="{ field }">
                      <Multiselect
                        :options="categorieArticlesOptions"
                        :searchable="true"
                        track-by="label"
                        label="label"
                        v-model = "field.value"
                        v-bind = "field"
                        placeholder="Sélectionner la catégorie"
                      />
                    </Field>
                  <ErrorMessage name="categorieArticle" class="text-danger"/>
                  </div>
                </div>

                 
                <!-- <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Publié l'article ? <span class="text-danger">*</span>
                    </label>
                    <Field  name="estPublie"  v-slot="{ field }">
                      <Multiselect
                        :options="publi"
                        :searchable="true"
                        track-by="label"
                        label="label"
                        v-model = "field.value"
                        v-bind = "field"
                        placeholder="Sélectionner l'option"
                      />
                    </Field>  
                  <ErrorMessage name="estPublie" class="text-danger"/>
                  </div>
                </div> -->
                <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Tags <span class="text-danger">*</span>
                    </label>
                    <Field name="tags" v-slot="{ field }">
                      <Multiselect
                        :options="tagsOptions"
                        :searchable="true"
                        multiple="true"
                        mode="tags"
                        track-by="label"
                        label="label"
                        v-model = "field.value"
                        v-bind = "field"
                        placeholder="Sélectionner le tag"
                      />
                    </Field>  
                  <ErrorMessage name="tags" class="text-danger"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Date de publication
                    </label>
                    <Field name="datePublication" type="date"  :value="getCurrentDate()"
                    class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="datePublication" class="text-danger"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Source de l'article
                    </label>
                    <Field name="source" type="text" 
                    class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="source" class="text-danger"/>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-15 mb-sm-20 mb-md-25">
                    <label class="d-block text-black fw-semibold mb-10">
                      Niveau 
                    </label>
                    <Field name="niveau" type="text" 
                    class="form-control shadow-none fs-md-15 text-black"/>
                    <ErrorMessage name="niveau" class="text-danger"/>
                  </div>
                </div>
              </div>
              </div>
              <div class="tab-pane fade p-10" id="profile-tab-pane" role="tabpanel" tabindex="0">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10" >
                        Meta title
                      </label>
                      <Field name="metaTitle" type="text" 
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le meta title"/>
                    <ErrorMessage name="metaTitle" class="text-danger"/>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10" >
                        Meta Keyword 
                      </label>
                      <Field name="metaKeyword" type="text" 
                      class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le meta keyword"/>
                    <ErrorMessage name="metaKeyword" class="text-danger"/>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label class="d-block text-black fw-semibold mb-10">
                        Meta description
                      </label>
                      <div class="mb-0">
                        <QuillEditor
                          ref="metaDescriptionEditor"
                          theme="snow"
                          placeholder="Ecrire la meta description"
                          toolbar="full"
                          name="metaDescription"
                        />
                      </div>
                      <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                      <!-- <p class="text-danger" v-if="!showA.value">Ce champ est obligatoire</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit"
                >
                    Ajouter un article
                </button>
                <router-link to="/liste-articles" 
                    class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                    <span class="position-relative"></span>Annuler</router-link>
              </div>
        </div>
      </div>
    </Form>
  </div>
</div>

    <!-- <div 
      class="modal fade createNewModal"
      id="PreviewOrActiveArticleModal"
      tabindex="-1"
      ref="addPreviewOrActiveArticleModalRef"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-15 p-md-40">
          <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">
            L'article: {{ createdArticle?.titre }} a bien été créé
          </h5>
          </div>
          <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25" style="text-align:center">
            <div class="row">
              <div class="col-md-12">
                <div class="d-flex align-items-center text-center">
                  <button
                    class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                     @click="previewArticle(createdArticleId)"
                  >
                  Prévisualiser l'article
                  </button>
                  <button @click="switchArticle(createdArticleId)" 
                      class=" btn btn-danger transition border-0 lh-1 fw-medium">
                      <span class="position-relative"></span>Activer l'article</button>
                </div>
               </div>
          </div>
        </div>
          <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div> -->
    
    <div class="modal fade createNewModal"
     id="PreviewOrActiveArticleModal"
     tabindex="-1"
     ref="addPreviewOrActiveArticleModalRef"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md"> 
    <div class="modal-content p-15 p-md-40">
      <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
        <h5 class="modal-title text-black">
          L'article: {{ createdArticle?.titre }} a bien été créé
        </h5>
      </div>
      <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25 text-center">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex align-items-center justify-content-center mb-3">
              <button class="default-btn me-3 transition border-0 fw-medium text-white pt-2 pb-2 ps-3 pe-3 rounded-1 fs-md-15 fs-lg-16 bg-success"
                      @click="previewArticle(createdArticle?.alias, createdArticle?.alias)">
                Prévisualiser l'article
              </button>
              <button @click="switchArticle(createdArticle?.alias, createdArticle?.alias)" 
                      class="default-btn me-3 transition border-0 fw-medium text-white pt-2 pb-2 ps-3 pe-3 rounded-1 fs-md-15 fs-lg-16 bg-danger">
                      Activer l'article
              </button>
            </div>
          </div>
        </div>
      </div>
      <button type="button"
              class="btn-close shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close">
      </button>
    </div>
  </div>
</div>


</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success, showModal, hideModal,generateSlug } from "../../utils/utils";
import { useRoute, useRouter } from "vue-router";
import ApiService from "../../services/ApiService";
import { Article } from "../../models/Article";
import * as Yup from "yup";
import Multiselect from '@vueform/multiselect'
import axios from "axios";
import DropZone from "dropzone-vue";

export default defineComponent({
  name: "AddArticle",
  components: {
    Form,
    Field,
    ErrorMessage,
    DropZone,
    Multiselect,
  },
  
  setup: (props, { emit }) => {
    const articleSchema = Yup.object().shape({
      titre: Yup.string().required("Le titre est obligatoire"),
      sousTitre: Yup.string().required("Le sous titre est obligatoire"),
      contenu: Yup.string().notRequired(),
      alias: Yup.string().required("L'alias est obligatoire"),
      metaTitle: Yup.string().notRequired(),
      metaKeyword: Yup.string().notRequired(),
      metaDescription: Yup.string().notRequired(),
      urlImage: Yup.mixed().notRequired(),
      icone: Yup.mixed().notRequired(),
      tags: Yup.array().required("Le tag est obligatoire"),
      datePublication: Yup.date().notRequired(),
      fichiers: Yup.mixed().notRequired(),
      source: Yup.string().notRequired(),
      categorieArticle: Yup.number().typeError('Veuillez entrer des chiffres').required("La categorie est obligatoire"),
      niveau: Yup.number().typeError('Veuillez entrer des chiffres').notRequired(),
    });

    const articleForm = ref<Article | null>(null);
    const router = useRouter();
    const text = ref("Glissez et déposez ou cliquez ici")

    const contenuEditor = ref();
    const metaDescriptionEditor = ref();
    const empty = ref("<p><br></p>")
    const tagsOptions = ref([]);
    const categorieArticlesOptions = ref([]);
    const toutFichier = ref<Array<File>>([]);
    const createdArticle = ref();
    const createdArticleId = ref();
    const createdArticleIdd = ref();
    const addPreviewOrActiveArticleModalRef = ref<null | HTMLElement>(null);
    const title= ref('');
    const alias= ref();

    watch(title, (newValue) => {
      console.log('title', title.value)
      console.log('generateSlug', alias.value)
      alias.value = generateSlug(newValue)
      console.log('after', alias.value)
    })

    const publi =ref( 
    [
      {
          value: true ,
          label: "Publié"
      },
      {
          value: false ,
          label: "Dépublié"
      }
    ]);
      
      

    onMounted(()=> {
      fetchTags();
      fetchCategorieArticles()
    })

    const getCurrentDate = () => {
          const today = new Date();
          const day = String(today.getDate()).padStart(2, '0');
          const month = String(today.getMonth() + 1).padStart(2, '0');
          const year = today.getFullYear();
          return `${year}-${month}-${day}`;
      }

    const fetchTags = async () => {
      try {
        const response = await ApiService.get('/tags?limit=0');
        const tagsData = response.data.data.data;
        tagsOptions.value = tagsData.map((tags) => ({
          value: tags.id,
          label: `${tags.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const fetchCategorieArticles = async () => {
      try {
        const response = await ApiService.get('/categorieArticles?limit=0');
        console.log("00000 ===> ",response);
        const categorieArticlesData = response.data.data.data;
        categorieArticlesOptions.value = categorieArticlesData.map((categorieArticle) => ({
          value: categorieArticle.id,
          label: `${categorieArticle.nom}`,
        }));
      } catch (error) {
        //
      }
    };

    const onFileAdd = (file:any) => {
      toutFichier.value.push(file["file"]);
    };

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(contenuEditor.value.getHTML() !== empty.value) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(contenuEditor.value.getHTML() === empty.value) {
        showA.value = true;
        show.value = "1"
      }
    }

    const addArticle = async (values, { resetForm }) => {
      console.log("donees envoyeessssssssssssssssss", values);
      console.log("LEs fichiers ==> ", toutFichier.value);
      if(toutFichier.value.length > 0){
        values['autreFichier[]'] = [];
        values['autreFichier[]'] =  toutFichier.value.map((fichier) => (fichier));
      }
      values['contenu'] = contenuEditor.value.getHTML();
      values['metaDescription'] = metaDescriptionEditor.value.getHTML();
      if(values['contenu'] !== empty.value) {
        showA.value = false;
      }
      else if(values['contenu'] === empty.value) {
        showA.value = true;
      }
      // values['estPublie'] = JSON.parse(values['estPublie']);
      values['alias'] = alias.value;
      if(showA.value === false) {
      console.log("requetttttttttttt", values);
      // ApiService.post("/articles", values)
        axios.post("/articles",values, { headers: { 'Content-Type': 'multipart/form-data','Accept': '*/*' } })
          .then(({ data }) => {
            if (data.code == 201) {
              success(data.message);
              // resetForm();
              // router.push({ name: "ListeArticlePage" });
              const modalEl = document.getElementById("PreviewOrActiveArticleModal");
              if (modalEl) {
                showModal(modalEl);
              }
              createdArticle.value = data.data;
              console.log("TYTYTYTYRERRREFTFVYG ===> ",createdArticle.value)
              createdArticleId.value = data.data.alias;
              //createdArticleIdd.value = data.data.id
              console.log('Article', createdArticle.value)
              console.log('createdArticleId', createdArticleId.value)
              router.push({ name: "ListeArticlePage" });

            }
          })
          .catch(({ response }) => {
            error(response.data.message);
          });
      }
    };

    const previewArticle = (alias: string, createdArticleIdd: number) => {
      console.log('Id; id', createdArticleIdd)
      console.log('alisa', alias)
      const url = `http://localhost:8081/#/preview-article/${alias}`;
      // window.location.href = url;
      window.open(url, '_blank');
    };

    const switchArticle = (id: number, createdArticleIdd: number) => {
      console.log('Id; id', id)
      axios.put(`/article/publie/${createdArticleIdd}`)
        .then(({ data }) => {
          if (data.code == 200) {
            success(data.message);
            router.push({ name: "ListeArticlePage" });
            hideModal(addPreviewOrActiveArticleModalRef.value);
            emit('close');
            emit('getAllArticle',data.data);
            router.push({ name: "ListeArticlePage" });

          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };


    return { 
      articleSchema, 
      addArticle,
      articleForm,
      contenuEditor, metaDescriptionEditor,
       showA, empty ,
       onFileAdd,
       publi, tagsOptions,
       categorieArticlesOptions,
       onSubmit, show, getCurrentDate,
       createdArticle,
      previewArticle,
      switchArticle,
      createdArticleId,
      addPreviewOrActiveArticleModalRef,
      alias, title, createdArticleIdd
    };
  },
});
</script>

<style scoped>
  @import 'dropzone-vue/dist/dropzone-vue.common.css';
  
</style>

