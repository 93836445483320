<template>
    <BreadCrumb PageTitle="Liste des apropos" />
    <ListeApropos/>
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import ListeApropos from "../../components/Apropos/ListeApropos.vue";

    export default defineComponent({
      name: "ListeAproposPage",
      components: {
        BreadCrumb,
        ListeApropos,
      },
    });
  </script>