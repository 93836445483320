<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="infoFooterForm" @submit="editInfoFooter" :validation-schema="infoFooterSchema" :initial-values="infoFooterForm">
        <div class="row">
            <!-- <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Image
                </label>
                <Field name="urlImage" type="file" 
                      accept="image/jpeg, image/png application/pdf"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Choisir une image"/>
                <ErrorMessage name="urlImage" class="text-danger"/>
              </div>
            </div> -->
            <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Politique et conditions d'utilisation générale
                  </label>
                  <div class="mb-0">
                <QuillEditor
                  ref="politiqueConditionEditor"
                  theme="snow"
                  placeholder="Écrire la politique"
                  toolbar="full"
                  name="politiqueCondition"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="politiqueCondition" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Expédition et livraison
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="expeLivraisonEditor"
                  theme="snow"
                  placeholder="Écrire les informations"
                  toolbar="full"
                  name="expeLivraison"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="expeLivraison" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Politique de retour et de remboursement
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="politiqueRREditor"
                  theme="snow"
                  placeholder="Écrire la politiqueRR"
                  toolbar="full"
                  name="politiqueRR"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="politiqueRR" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Qui sommes nous ?
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="quiSommeNousEditor"
                  theme="snow"
                  placeholder="Qui sommes-nous"
                  toolbar="full"
                  name="quiSommeNous"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="quiSommeNous" class="text-danger"/>
                </div>
              </div>
              
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Contact
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="contactEditor"
                  theme="snow"
                  placeholder="Renseigner le contact"
                  toolbar="full"
                  name="contact"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="contact" class="text-danger"/>
                </div>
              </div>

              <!-- <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Mentions Légales
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="mentionLegaleEditor"
                  theme="snow"
                  placeholder="Écrire les mentions"
                  toolbar="full"
                  name="mentionLegale"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="mentionLegale" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Données personnelles
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="donnePersonnelleEditor"
                  theme="snow"
                  placeholder="Écrire les données"
                  toolbar="full"
                  name="donnePersonnelle"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="donnePersonnelle" class="text-danger"/>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Cookies
                  </label>
                  <div class="mb-0">
                <QuillEditor
                ref="cookieEditor"
                  theme="snow"
                  placeholder="Écrire les cookies"
                  toolbar="full"
                  name="cookie"
                />
              </div>
              <p class="text-danger" v-if="show === '1'">Ce champ est obligatoire</p>
                <ErrorMessage name="cookie" class="text-danger"/>
                </div>
              </div> -->
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="onSubmit()"
            >
                Modifier
            </button>
            <router-link to="/liste-infoFooter" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { error, success } from "../../utils/utils";
import { useRoute, useRouter } from "vue-router";
import ApiService from "../../services/ApiService";
import { InfoFooter } from "../../models/InfoFooter";
import * as Yup from "yup";
import axios from "axios";

export default defineComponent({
  name: "EditInfoFooter",
  components: {
    Form,
    // Field,
    ErrorMessage,
  },
  setup: () => {
    const infoFooterSchema = Yup.object().shape({
      // image: Yup.string().notRequired(),
      // urlImage: Yup.mixed().notRequired(),
      expeLivraison: Yup.string().notRequired(),
      politiqueRR: Yup.string().notRequired(),
      politiqueCondition: Yup.string().notRequired(),
      contact: Yup.string().notRequired(),
      quiSommeNous: Yup.string().notRequired(),
    });

    const infoFooterForm = ref<InfoFooter | null>(null);
    const router = useRouter();
    const route = useRoute();

    const quiSommeNousEditor = ref();
    const expeLivraisonEditor = ref();
    const politiqueRREditor = ref();
    const politiqueConditionEditor = ref();
    const contactEditor = ref();
    const donnePersonnelleEditor = ref();
    const mentionLegaleEditor = ref();
    const cookieEditor = ref();
    const empty = ref("<p><br></p>")

    const showA = ref(false)
    const show = ref("0")
    const onSubmit = () => {
      if(
        quiSommeNousEditor.value.getHTML() !== empty.value || expeLivraisonEditor.value.getHTML() !== empty.value || 
        contactEditor.value.getHTML() !== empty.value || politiqueConditionEditor.value.getHTML() !== empty.value ||
        politiqueRREditor.value.getHTML() !== empty.value || cookieEditor.value.getHTML() !== empty.value ||
        donnePersonnelleEditor.value.getHTML() !== empty.value || mentionLegaleEditor.value.getHTML() !== empty.value
      ) {
        showA.value = false;
        console.log('hijgrgrkjgmerjm')
      }
      else if(quiSommeNousEditor.value.getHTML() === empty.value ||
       expeLivraisonEditor.value.getHTML() === empty.value ||
        contactEditor.value.getHTML() === empty.value ||
         politiqueConditionEditor.value.getHTML() === empty.value ||
          politiqueRREditor.value.getHTML() === empty.value 
          // ||
          // cookieEditor.value.getHTML() === empty.value ||
          // donnePersonnelleEditor.value.getHTML() === empty.value ||
          // mentionLegaleEditor.value.getHTML() === empty.value
        ) {
        showA.value = true;
        show.value = "1"
        console.log('aaaaaaaaaaa')
      }
    }

    function getInfoFooter(id:number) {
      ApiService.get("/infoFooter/"+id.toString())
        .then(({ data }) => {
          quiSommeNousEditor.value.setHTML(data.data.quiSommeNous);
          expeLivraisonEditor.value.setHTML(data.data.expeLivraison);
          politiqueConditionEditor.value.setHTML(data.data.politiqueCondition);
          politiqueRREditor.value.setHTML(data.data.politiqueRR);
          contactEditor.value.setHTML(data.data.contact);
          // cookieEditor.value.setHTML(data.data.cookie);
          // donnePersonnelleEditor.value.setHTML(data.data.donnePersonnelle);
          // mentionLegaleEditor.value.setHTML(data.data.mentionLegale);
          for (const key in data.data) {
            infoFooterForm.value?.setFieldValue(key, 
            (typeof data.data[key] === 'object' && data.data[key] !== null)? data.data[key].id :data.data[key]
          );
          }
      })
      .catch(({ response }) => {
        error(response.data.message);
      });
    } 

    const editInfoFooter = async (values, {resetForm}) => {
      if(values['politiqueCondition'] !== empty.value &&
       values['expeLivraison'] !== empty.value &&
        values['contact'] !== empty.value &&
         values['politiqueRR'] !== empty.value &&
          values['quiSommeNous'] !== empty.value
          //  &&
          //  values['cookie'] !== empty.value &&
          //   values['donnePersonnelle'] !== empty.value &&
          //    values['mentionLegale'] !== empty.value
            ) {
        showA.value = false;
      }else if(values['politiqueCondition'] === empty.value &&
       values['expeLivraison'] === empty.value &&
        values['contact'] === empty.value &&
         values['quiSommeNous'] === empty.value &&
          values['politiqueRR'] === empty.value
          //  &&
          //  values['cookie'] === empty.value &&
          //   values['donnePersonnelle'] === empty.value &&
          //    values['mentionLegale'] === empty.value
            ) {
        showA.value = true;
      }
      
      values['quiSommeNous'] = quiSommeNousEditor.value.getHTML();
      values['expeLivraison'] = expeLivraisonEditor.value.getHTML();
      values['politiqueCondition'] = politiqueConditionEditor.value.getHTML();
      values['politiqueRR'] = politiqueRREditor.value.getHTML();
      values['contact'] = contactEditor.value.getHTML();
      // values['cookie'] = cookieEditor.value.getHTML();
      // values['donnePersonnelle'] = donnePersonnelleEditor.value.getHTML();
      // values['mentionLegale'] = mentionLegaleEditor.value.getHTML();
      console.log('Donnéeess ', values)
      if(showA.value === false) {
      axios.put("/infoFooter/"+values.id,values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            // resetForm();
            router.push({ name: "ListeInfoFooterPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    }
    };

    onMounted(() => {
      if(route.params.id) {
        getInfoFooter(parseInt(route.params.id as string));
      }
    });

    return { infoFooterSchema, infoFooterForm, editInfoFooter,
      politiqueConditionEditor,
    expeLivraisonEditor, contactEditor, quiSommeNousEditor,
    cookieEditor,donnePersonnelleEditor,mentionLegaleEditor, showA, empty,
    onSubmit, show, politiqueRREditor };
  },
});
</script>
