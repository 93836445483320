<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center" v-if="checkPermission('AddArticle')">
        <router-link
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-article"
        >
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Ajouter un article
        </router-link>
        <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button> -->
      </div>
      <div class="d-flex align-items-center">
        <form
          class="search-box position-relative me-15"
          @submit.prevent="rechercher"
        >
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un article"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <!-- <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Image
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Catégorie
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Titre
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Sous titre
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Catégorie
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Alias
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Statut
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text pe-0"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(article, index) in articles" :key="index">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <img
                  :src="getUrlApiForFiles(article.urlImage)"
                  alt="Image"
                  width="40"
                  height="40"
                />
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{
                  article?.categorieArticle?.nom.length > 20
                    ? article.categorieArticle?.nom.slice(0, 20) + " ..."
                    : article.categorieArticle?.nom
                }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{
                  article.titre.length > 20
                    ? article.titre.slice(0, 20) + " ..."
                    : article.titre
                }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{
                  article.sousTitre.length > 20
                    ? article.sousTitre.slice(0, 20) + " ..."
                    : article.sousTitre
                }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{
                  article?.categorieArticle?.nom.length > 20
                    ? article.categorieArticle?.nom.slice(0, 20) + " ..."
                    : article.categorieArticle?.nom
                }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{
                  article.alias.length > 20
                    ? article.alias.slice(0, 20) + " ..."
                    : article.alias
                }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ article.estPublie ? "Publié" : "Dépublié" }}
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text pe-0"
              >
                <div class="dropdown">
                  <span
                    class="badge text-white bg-primary fs-15 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Actions
                    <i
                      class="flaticon-chevron-2 position-relative ms-5 top-2 fs-15"
                    ></i>
                  </span>
                  <ul class="dropdown-menu">
                    <li v-if="checkPermission('EditArticle')"> 
                      <router-link
                        :to="{
                          name: 'EditArticlePage',
                          params: { id: article.id },
                        }"
                        class="dropdown-item d-flex align-items-center"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i
                        >Modifier</router-link
                      >
                    </li>
                    <li v-if="checkPermission('AddArticleTag')">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#AddNewTagModal"
                        @click="openAddNewTagModal(article)"
                      >
                        <i
                          class="flaticon-plus lh-1 me-8 position-relative top-1"
                        ></i>
                        Ajouter de (s) tag (s)
                      </a>
                    </li>
                    <li v-if="checkPermission('SwitchArticle')">  
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        @click="switchArticle(article.id)"
                      >
                        <i
                          class="flaticon-flash lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="!article.estPublie"> Publié l'article</span>
                        <span v-if="article.estPublie">
                          Dépublier l'article</span
                        >
                      </a>
                    </li>
                    <li v-if="article.categorieArticle?.id == 22">
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="#"
                        @click="alaUne(article.id)"
                      >
                        <i
                          class="flaticon-flash lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="!article.alaUne">En vedette</span>
                        <span v-if="article.alaUne">Masquer</span>
                      </a>
                    </li>
                    <li  v-if="checkPermission('ViewArticle')">
                      <router-link
                        :to="{
                          name: 'ViewArticlePage',
                          params: { id: article.id },
                        }"
                        class="dropdown-item d-flex align-items-center"
                      >
                        <i
                          class="flaticon-eye lh-1 me-8 position-relative top-1"
                        ></i
                        >Détails
                      </router-link>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="
                          suppression(
                            article.id,
                            articles,
                            'articles',
                            `l\'article ${article.id}`
                          )
                        "
                      >
                        <i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Supprimer
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent
          :page="page"
          :totalPages="totalPages"
          :totalElements="totalElements"
          :limit="limit"
          @paginate="handlePaginate"
        />
      </div>
    </div>
  </div>
  <AddNewTagModal
    :selectedArticle="selectedArticle"
    :selectedArticleId="selectedArticleId"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { Article } from "../../models/Article";
import ApiService from "../../services/ApiService";
import {
  suppression,
  error,
  getUrlApiForFiles,
  success,
} from "../../utils/utils";
import PaginationComponent from "../../components/Utilities/Pagination.vue";
import JwtService from "../../services/JwtService";
import AddNewTagModal from "./AddNewTagModal.vue";
import axios from "axios";

export default defineComponent({
  name: "ListeArticle",
  components: {
    PaginationComponent,
    AddNewTagModal,
  },
  setup() {
    onMounted(() => {
      getAllArticle();
    });

    const articles = ref<Array<any>>([]);
    const article = ref<any>();

    // BEGIN PAGINATE
    const searchTerm = ref("");
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllArticle(page_, limit_);
      } catch (error) {
        //
      }
    };

    function rechercher() {
      getAllArticle(page.value, limit.value, searchTerm.value);
    }

    const alaUne = async (id: any) => {
      //
      try {
        const response = await axios.put(`/alUne/articles/${id}`, {});
        success(response.data.message);
        await toggleAlaUne(id);
      } catch (errorr) {
        error(errorr);
      }
    };

    // END PAGINATE

    function getAllArticle(page = 1, limi = 10, searchTerm = "") {
      return ApiService.get(
        `/articles?page=${page}&limit=${limi}&mot=${searchTerm}&`
      )
        .then(({ data }) => {
          articles.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }

    function moddifier(Editarticle: any) {
      article.value = Editarticle;
    }

    const deletearticle = (id: number) => {
      ApiService.delete(`/articles/${id}`)
        .then(({ data }) => {
          Swal.fire({
            text: data.message,
            toast: true,
            icon: "success",
            title: "General Title",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            heightAuto: false,
          });
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });

      for (let i = 0; i < articles.value.length; i++) {
        if (articles.value[i].id === id) {
          articles.value.splice(i, 1);
        }
      }
    };

    const selectedArticle = ref<any | undefined>(undefined);
    const selectedArticleId = ref<number>();

    const openAddNewTagModal = (article: any) => {
      selectedArticle.value = { ...article };
      selectedArticleId.value = article.id;
    };

    const success = (message) => {
      Swal.fire({
        text: message,
        toast: true,
        icon: "success",
        title: "Message",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        heightAuto: false,
      });
    };

    const switchArticle = (id) => {
      axios
        .put(`/article/publie/${id}`)
        .then(({ data }) => {
          if (data.code == 200) {
            success(data.message);
            getAllArticle();
          }
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    };

    const toggleAlaUne = (articleId) => {
      try {
        const index = articles.value.findIndex(
          (article) => article.id === articleId
        );

        var article = articles.value[index];
        if (article) {
          article.alaUne = !article.alaUne;
          articles.value[index] = article;
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de l'état 'à la une':",
          error
        );
      }
    };

    const privileges = ref<Array<string>>(JwtService.getPrivilege());

    const checkPermission = (name) => {
      return privileges.value.includes(name);
    };

    return {
      articles,
      checkPermission,
      getAllArticle,
      deletearticle,
      moddifier,
      suppression,
      page,
      totalPages,
      limit,
      alaUne,
      totalElements,
      handlePaginate,
      rechercher,
      searchTerm,
      getUrlApiForFiles,
      openAddNewTagModal,
      selectedArticle,
      selectedArticleId,
      switchArticle,
    };
  },
});
</script>

<style>
.truncate {
  width: 250px;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
